import React, { useEffect } from "react";
import { roles } from "../../../content/careers";
import PageCaption from "./PageCaption";
import LookFor from "./LookFor";
import Skills from "./Skills";

const Roles = (props) => {
  const paramsName = props.match.params.name;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {roles.map(
        (role) =>
          role.name === paramsName && (
            <>
              <PageCaption role={role} />
              <LookFor role={role} />
              <Skills role={role} />
            </>
          )
      )}
    </>
  );
};

export default Roles;
