import React from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import { subProductListItems } from "../../content/Home";
import { subProductIcon } from "../../content/Home";
import _ from "lodash";
import MainContainer from "../../components/mainContainer";

const useStyles = makeStyles((theme) => ({
  subProductListContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
  },
  subProductListClass: {
    width: "85%",

    [theme.breakpoints.down("md")]: {
      width: "80%",
    },

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  subProductListTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 28,
    fontFamily: "Nunito",
    maxWidth: "fit-content",

    [theme.breakpoints.down("xs")]: {
      borderBottom: "none",
      fontSize: 24,
    },
  },
  subProductListSubTitle: {
    fontSize: 14,
    textAlign: "center",
    maxWidth: "fit-content",
    padding: 10,
    fontWeight: 500,
  },
  subProductIconListContainer: {
    position: "relative",
    width: "70%",
    borderBottom: "2px solid #444444",
    marginTop: 40,
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      width: "100%",
      textAlign: "center",
      borderBottom: "none",
    },
  },
  subProductIconList: {
    display: "flex",
    justifyContent: "space-between",
    listStyleType: "none",
    width: "100%",
    padding: 0,
    paddingTop: 20,
    margin: 0,
    fontFamily: "Montserrat",
    position: "absolute",
    top: "-58px",

    [theme.breakpoints.down("xs")]: {
      position: "static",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
  },

  subProductIconClass: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginBottom: 10,
    },
  },

  image: {
    height: 80,
    cursor: "pointer",
  },

  subProductListBody: {
    marginTop: "10%",
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      display: "black",
      marginTop: "10px",
    },
  },

  subProductItemsClass: {
    // display: "flex",

    [theme.breakpoints.down("xs")]: {
      paddingBottom: 10,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },

  subProductItemsTitle: {
    fontWeight: 600,
    fontFamily: "Nunito",
    fontSize: 16,
    marginBottom: 5,
    textAlign: "center",

    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      margin: 0,
      paddingTop: 10,
      textAlign: "left",
    },
  },

  subProductItemsBody: {
    fontWeight: 400,
    margin: 0,
    fontSize: 14,
    textAlign: "center",
    width: "75%",

    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      marginTop: 10,
    },
  },
  subProductItemsContent: {
    // paddingLeft: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("xs")]: {
      paddingLeft: 10,
    },
  },

  iconName: {
    fontSize: 16,
    textAlign: "center",
  },
  iconNameActive: {
    fontWeight: "bold",
    fontSize: 16,
    textAlign: "center",
  },

  icon: {
    height: 75,
    [theme.breakpoints.down("xs")]: {
      height: 50,
    },
  },

  zigZag: {
    [theme.breakpoints.down("xs")]: {
      marginBottom: 15,
    },
  },

  preContent: {
    fontSize: 20,
    margin: "24px 0",
    fontFamily: "Montserrat, sans-serif",
    width: "100%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      margin: "15px 28px",
    },
  },

  subProductListTitleContent: {
    fontWeight: 600,
    fontFamily: "Montserrat, sans-serif",
    fontSize: 32,
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
      margin: "10px",
      textAlign: "center",
      lineHeight: 1.5,
    },
  },

  imageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginLeft: 10,
    },
  },

  subProductItemsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    margin: "30px 0",

    [theme.breakpoints.down("xs")]: {
      display: "block",
      margin: 0,
    },
  },

  subProductFeatureList: {
    width: "33%",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  subProductIconContainer: {
    [theme.breakpoints.down("xs")]: {
      margin: "15px 28px",
    },
  },

  subProductContent: {
    width: "63%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      fontSize: 14,
      margin: "15px 28px",
    },
  },

  preContentContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function SubProductList() {
  const [currentProduct, setCurrentProduct] = React.useState({
    productId: 201,
    currentIndex: 0,
  });
  const theme = useTheme();
  const mobileMatcher = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    subProductListContainer,
    subProductListClass,
    subProductListTitle,
    subProductListBody,
    subProductItemsContainer,
    subProductItemsClass,
    subProductItemsTitle,
    subProductItemsBody,
    subProductItemsContent,
    subProductIconList,
    subProductIconClass,
    image,
    iconName,
    iconNameActive,
    icon,
    zigZag,
    preContent,
    subProductListTitleContent,
    imageContainer,
    subProductIconListContainer,
    subProductFeatureList,
    subProductIconContainer,
    subProductContent,
    preContentContainer,
  } = useStyles();
  const history = useHistory();
  const changeProduct = (id) => {
    const resIndex = subProductListItems.findIndex((value) => {
      return value.productId === id;
    });

    currentProduct.currentIndex !== resIndex
      ? setCurrentProduct({
          ...currentProduct,
          productId: id,
          currentIndex: resIndex,
        })
      : setCurrentProduct({
          ...currentProduct,
          currentIndex: null,
          productId: null,
        });
  };

  return (
    <>
      <MainContainer>
        <div className={subProductListContainer}>
          <div className={subProductListClass}>
            <div className={subProductListTitle}>
              <div className={subProductListTitleContent}>
                Manage Your Customer Life Cycle with{" "}
                <span style={{ color: "#f07663", fontWeight: "bold" }}>
                  CASA
                </span>{" "}
                CDP & AI Engine
              </div>

              <div className={preContentContainer}>
                <div className={preContent}>
                  Let our AI engine guide you to adopt a data driven approach to
                  automate Personalized engagements at all touch points of your
                  customer journey.
                </div>
              </div>
              <div className={subProductIconListContainer}>
                <ul className={subProductIconList}>
                  {subProductIcon.map((value, index) => (
                    <li className={index % 2 === 0 ? zigZag : ""}>
                      <div className={subProductIconClass}>
                        <img
                          src={
                            value.id === currentProduct.productId
                              ? value.active
                              : value.inActive
                          }
                          alt=""
                          className={image}
                          onClick={() => {
                            changeProduct(value.id);
                          }}
                        />

                        <div
                          className={
                            value.id === currentProduct.productId
                              ? iconNameActive
                              : iconName
                          }
                        >
                          {value.name}
                        </div>
                        {mobileMatcher &&
                          index === currentProduct.currentIndex && (
                            <>
                              <div className={subProductListBody}>
                                <div className={subProductContent}>
                                  {_.chunk(
                                    subProductListItems[
                                      currentProduct.currentIndex
                                    ].title,
                                    3
                                  )}
                                </div>
                              </div>
                              <div className={subProductIconContainer}>
                                {_.chunk(
                                  subProductListItems[
                                    currentProduct.currentIndex
                                  ].items,
                                  3
                                ).map((value) => (
                                  <div className={subProductItemsContainer}>
                                    {value.map((value2) => (
                                      <div className={subProductFeatureList}>
                                        <div className={subProductItemsClass}>
                                          <div className={imageContainer}>
                                            <img
                                              src={value2.image}
                                              alt=""
                                              className={icon}
                                            />
                                          </div>

                                          <div
                                            className={subProductItemsContent}
                                            onClick={() =>
                                              history.push(value2.path)
                                            }
                                          >
                                            <p className={subProductItemsTitle}>
                                              {value2.title}
                                            </p>
                                            <p className={subProductItemsBody}>
                                              {value2.content}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {!mobileMatcher && (
              <div className={subProductListBody}>
                <div className={subProductContent}>
                  {_.chunk(
                    subProductListItems[currentProduct.currentIndex]?.title,
                    3
                  )}
                </div>
              </div>
            )}

            {!mobileMatcher && (
              <div className={subProductIconContainer}>
                {_.chunk(
                  subProductListItems[currentProduct.currentIndex]?.items,
                  3
                ).map((value) => (
                  <div className={subProductItemsContainer}>
                    {value.map((value2) => (
                      <div className={subProductFeatureList}>
                        <div className={subProductItemsClass}>
                          <div className={imageContainer}>
                            <img src={value2.image} alt="" className={icon} />
                          </div>

                          <div className={subProductItemsContent}>
                            <p className={subProductItemsTitle}>
                              {value2?.title}
                            </p>
                            <p className={subProductItemsBody}>
                              {value2?.content}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </MainContainer>
    </>
  );
}

export default SubProductList;
