import {
  AppBar,
  Button,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  useMediaQuery,
  withStyles,
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { NavbarLinks, Products } from '../content/Home';
import { getKey, STATE } from '../utils/routesState';
import Brand from './Brand';
import MainContainer from './mainContainer';
import MobileMenu from './mobileMenu';
import ProductsBox from './productsBox';
import Resources from './Resources';

const useStyles = makeStyles((theme) => ({
  navbar: {
    backgroundColor: theme.palette.background.default,
    boxShadow: ` 7px 3px 10px #00000029`,
    height: 90,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      height: 60,
    },
  },
  title: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  navbarStyle: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  imageLogoContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 122,
    height: '100%',
    [theme.breakpoints.down('xs')]: {
      width: 74,
    },
  },
  imageLogo: {
    height: 66,
  },
  navbarMenu: {
    padding: theme.spacing(1),

    '& > .MuiTabs-root > .MuiTabs-fixed > .MuiTabs-centered > .Mui-selected  ': {
      color: '#F07663',
      textDecoration: 'underline',
      textUnderlineOffset: '10px',
    },
  },
  links: {
    '@media (max-width:1024px)': {
      fontSize: 17,
      minWidth: 'unset',
    },
    fontSize: 16,
    marginLeft: 4,
    minWidth: 50,
    padding: 6,
    paddingLeft: 0,

    '& > span': {
      fontWeight: theme.typography.semiBold,
      borderRight: '1px solid black',
      paddingRight: 10,
    },
  },

  lastLinks: {
    '@media (max-width:1024px)': {
      fontSize: 17,
      minWidth: 'unset',
    },
    fontSize: 16,
    marginLeft: 4,
    minWidth: 50,
    padding: 6,
    paddingLeft: 0,
    '& > span': {
      fontWeight: theme.typography.semiBold,
      paddingRight: 10,
    },
  },

  contactBtn: {
    '@media (max-width:1024px)': {
      fontSize: '16px',
      minWidth: '125px',
      minHeight: '35px',
    },
    marginLeft: theme.spacing(2),
    minWidth: 90,
    minHeight: 40,
    color: '#FFFFFF',
    boxShadow: 'none',
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    backgroundColor: 'white',
    border: 'none',
  },
  activeSolutions: {
    // color: "#F07663",
    color: '#f9482d',
    textDecoration: 'underline',
    textUnderlineOffset: '10px',
  },
  contactUs: {
    textDecoration: "none",
    color: "black"
  }
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    maxWidth: '60em',
  },
}))(Tooltip);

const Navbar = (props) => {
  const {
    navbar,
    title,
    links,
    activeSolutions,
    lastLinks,
    contactBtn,
    navbarMenu,
    navbarStyle,
    link,
    contactUs,
  } = useStyles();
  const [value, setValue] = React.useState(-1);
  const history = useHistory();
  const handleChange = (event, newValue) => {
    newValue !== 1 && setValue(newValue);
    history.push(_.get(STATE, newValue));
  };

  const [show, setShow] = React.useState(false);
  const handleClickAway = () => {
    setShow(false);
  };

  const mobileMatcher = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const ContactUsBtn = (
    <a
      target='_blank'
      rel='noopener noreferrer'
      className={link}
      href='https://form.jotform.me/91042672900451'
    >
      <Button variant='contained' className={contactBtn} color='primary'>
        Contact Us
      </Button>
    </a>
  );
  useEffect(() => {
    const pathName = window.location.pathname;
    const newState = getKey(pathName);
    setValue(newState);
  }, [value]);

  const menu = mobileMatcher ? (
    <div className={title}>
      <div className={navbarMenu}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='unset'
            centered
          >
            <Tab disableRipple className={links} label='Home' />
            <LightTooltip
              title={<ProductsBox SubProductLinks={Products} />}
              arrow
              placement='top'
              interactive
            >
              <Tab
                disableRipple
                className={props.params ? clsx(links, activeSolutions) : links}
                label='Solutions'
                onClick={() => {
                  setShow(!show);
                }}
              />
            </LightTooltip>
            <Tab disableRipple className={links} label='Team' />
            <Tab disableRipple className={links} label='Careers' />
            <LightTooltip
              title={<Resources />}
              arrow
              placement='top'
              interactive
            >
              <Tab
                disableRipple
                className={links}
                label='Resources'
                onClick={() => {
                  setShow(!show);
                }}
              />
            </LightTooltip>

            <button
              className={link}
              // onClick={() =>
              //   window.ml_account('webforms', '5211053', 'u6u0x4', 'show')
              // }
            >
              <a href="#down" className={contactUs}><Tab disableRipple className={lastLinks} label='Contact Us' /></a>
            </button>
          </Tabs>
        </ClickAwayListener>
      </div>
    </div>
  ) : (
    <div>
      <MobileMenu items={NavbarLinks} unique={ContactUsBtn} />
    </div>
  );

  return (
    <AppBar position='static' className={navbar}>
      <Toolbar className={navbarStyle}>
        <MainContainer>
          <Grid container>
            {mobileMatcher && (
              <Grid item xs={6} lg={3} md={3}>
                <Brand />
              </Grid>
            )}
            <Grid item xs={12} lg={9} md={9}>
              {menu}
            </Grid>
          </Grid>
        </MainContainer>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
