import {makeStyles} from "@material-ui/core";
import React, {useEffect} from "react";

const useStyles = makeStyles((theme) => ({
  appContainer: {
    color: theme.palette.text.primary,
    fontFamily: theme.typography.fontFamily,
  },
}));
const AppContainer = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { appContainer } = useStyles();
  return <div className={appContainer}>{props.children}</div>;
};

export default AppContainer;
