import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
  btn: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: "bold",
    boxShadow: "0px 3px 6px #00000029",
    height: 45,
    // width: 265,
    width: 150,
    textTransform: "none !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      height: 40,
      width: "initial",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "& >span": {
      width: "100%",
      minWidth: 151,
      [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
      },
    },
  },
}));
const HeroButton = (props) => {
  const { btn } = useStyles();
  return (
    <Button
      onClick={props.onClick}
      variant="contained"
      color="primary"
      className={clsx(btn, props.className)}
    >
      {props.children}
    </Button>
  );
};

export default HeroButton;
