import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { culture, SubProducts } from "../../content/careers";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "'Nunito'",
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  title: {
    fontSize: 32,
    margin: 16,
    fontWeight: 900,
    color: "#F07663",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: 24,
    },
  },
  about: {
    fontSize: 20,
    width: "75%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "justify",
      width: "100%",
      fontSize: 18,
      padding: "2px 12px",
    },
  },
  videoFrame: {
    height: "300px",
    width: "400px",
    margin: 20,
    [theme.breakpoints.down("sm")]: {
      height: "190px",
      width: "250px",
    },
  },
  subproduct: {
    width: "70%",
    margin: "24px 0 24px 42px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
    },
  },
  imgContainer: {
    height: "50px",
    width: "50px",
    backgroundColor: "#2d3748",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  image: {
    padding: "16px",
  },
  subproductGrid: {
    display: "flex",
    padding: "20px  0",
  },
  SubProductTitle: {
    margin: 0,
    padding: "0 12px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 19,
    },
  },
  SubProductContent: {
    width: "94%",
    padding: "2px 12px",
    margin: 0,
    fontSize: 16,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const Culture = (props) => {
  const {
    root,
    title,
    videoFrame,
    about,
    subproduct,
    imgContainer,
    image,
    subproductGrid,
    SubProductTitle,
    SubProductContent,
  } = useStyles();
  return (
    <>
      <div className={root}>
        <div className={title}>{culture.title}</div>
        <div className={about}>{culture.about}</div>
        <video controls="true" className={videoFrame}>
          <source src={culture.source}></source>
        </video>
        <div className={subproduct}>
          <Grid container>
            {SubProducts &&
              SubProducts.map((SubProduct) => {
                return (
                  <Grid
                    item
                    sm={6}
                    xs={12}
                    lg={6}
                    className={subproductGrid}
                    key={SubProduct.id}
                  >
                    <div className={imgContainer}>
                      <img
                        src={SubProduct.source}
                        alt={SubProduct.title}
                        className={image}
                      />
                    </div>
                    <div>
                      <h3 className={SubProductTitle}>{SubProduct.title}</h3>
                      <p className={SubProductContent}>{SubProduct.content}</p>
                    </div>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Culture;
