import config from '../config/index';
import {NewTenantModel} from "../models/tenant/NewTenant.model";

const TENANT_MAINTENANCE_OTP = '/otp/create-tenant/sendOTP'
const TENANT_MAINTENANCE_CREATE = '/otp/create-tenant'

export function getOtpForEmail(email: string) {
    return fetch(`${config.ruleServerUrl}${TENANT_MAINTENANCE_OTP}`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            "email": email
        })
    }).then(r => r);
}

export function validateAndCreateTenant(tenantRequest: NewTenantModel) {
    return fetch(`${config.ruleServerUrl}${TENANT_MAINTENANCE_CREATE}`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify(tenantRequest)
    }).then(r => r);
}