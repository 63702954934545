import React from "react";
import CustomerBenifitHistory from "../../components/CustomBenifitHistory";
import Awards from "./Awards";
import PageCaption from "./PageCaption";
import Partners from "./Partners";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Navbar from "../../components/navbar";
import CustomerFeedback from "./CustomerFeedback";
import Footer from "../../components/footer"; // requires a loader
import Register from "./Register";
import SubProductList from "./SubProductList";
import HeadTagDetails from "./HeadDetails";

const Main = (props) => {
  return (
    <div>
      <Navbar />
      <div className="addHeight"></div>
      <HeadTagDetails />
      <PageCaption />
      <CustomerBenifitHistory />
      <SubProductList />
      <CustomerFeedback />
      <Register />
      <Awards />
      <Partners />
      <Footer />
    </div>
  );
};

export default Main;
