import React from "react";
import MainContainer from "../../components/mainContainer";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import HeroButton from "../../components/heroBtn";
import { Banner } from "../../content/Home";
const useStyles = makeStyles((theme) => ({
  pageContainer: {
    [theme.breakpoints.down("xs")]: {
      margin: "0 20px",
    },
  },
  boldTitle: {
    fontWeight: 700,
    color: theme.palette.text.primary,
    fontSize: 44,
    fontFamily: "Montserrat, sans-serif",
    width: "95%",

    [theme.breakpoints.down("md")]: {
      fontSize: 32,
      fontWeight: theme.typography.fontWeightBold,
      width: "100%",
      color: "#333333",
      lineHeight: 1.8,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      textAlign: "center",
      fontWeight: "bold",
      width: "100%",
      color: "#333333",
      marginTop: 10,
    },
  },
  titleSubtext: {
    fontWeight: 500,
    marginBottom: "5px",
    fontSize: 24,
    color: theme.palette.text.primary,
    fontFamily: "Nunito",
    // [theme.breakpoints.down("md")]: {
    //   fontSize: 16,
    // },
    [theme.breakpoints.down("xs")]: {
      fontWeight: 500,
      fontSize: 14,
      fontFamily: "Montserrat",
    },
  },

  featureListClass: {
    fontSize: 16,
    fontWeight: 400,
    paddingTop: 10,
    margin: 0,
    paddingLeft: 28,

    "&>li": {
      paddingBottom: 10,
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "left",
      fontSize: 14,
      // fontWeight: 600,
    },
  },

  captionContainer: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  image: {
    width: "90%",
    margin: "auto",
    paddingLeft: 50,
  },
  pageCaption: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  link: {
    textDecoration: "none",
    color: "#FFFFFF",
  },
}));
const PageCaption = (props) => {
  const {
    pageContainer,
    boldTitle,
    image,
    pageCaption,
    titleSubtext,
    captionContainer,
    featureListClass,
  } = useStyles();

  return (
    <div className={pageContainer}>
      <MainContainer>
        <Grid container wrap="wrap-reverse">
          <Grid item sm={7}>
            <div className={pageCaption}>
              <div className={captionContainer}>
                <Typography className={boldTitle}>
                  Drive Retail Sales with{" "}
                  <span style={{ color: "#f07663", fontWeight: "bold" }}>
                    CASA{" "}
                  </span>
                  CDP & AI Engine
                </Typography>
                <br />
                <Typography className={titleSubtext}>
                  {Banner.subTitle}
                </Typography>

                <ul className={featureListClass}>
                  {Banner.featureList.map((value, index) => (
                    <li key={index}>{value}</li>
                  ))}
                </ul>

                <br />

                <HeroButton
                  onClick={() =>
                    // window.ml_account("webforms", "5211053", "u6u0x4", "show")
                    window.location.assign("https://docs.google.com/forms/d/e/1FAIpQLSdRMpCs2uRl4fK87sQQbDRLsNc_eu14Ya_ERyDa__gtUUPg5w/viewform")
                  }
                >
                  Book a Demo
                </HeroButton>
              </div>
            </div>
          </Grid>
          <Grid item sm={5}>
            <img
              src={Banner.imageSource}
              alt="Undraw-Outline-Shopping-ga73"
              className={image}
            />
          </Grid>
        </Grid>
      </MainContainer>
    </div>
  );
};

export default PageCaption;
