import React from "react";
import Intelliclose from "./intelliclose/index";
import Journify from "./journify/index";
import Ubicuity from "./ubicuity/index";
import PWA from "./pwa/index";
import Offerio from "./offerio/index";
import RetenSee from "./retenSee/index";
import Pimkart from "./pimkart/index";
import FlipSell from "./flipSell/index";
import FieldSalesAssist from "./fieldSalesAssist/index";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

const Index = (props) => {
  const products = {
    intelliclose: {
      component: <Intelliclose />,
    },
    journify: {
      component: <Journify />,
    },
    ubicuity: {
      component: <Ubicuity />,
    },
    pwa: {
      component: <PWA />,
    },
    offerio: {
      component: <Offerio />,
    },
    retenSee: {
      component: <RetenSee />,
    },
    pimkart: {
      component: <Pimkart />,
    },
    flipSell: {
      component: <FlipSell />,
    },
    fieldSalesAssist: {
      component: <FieldSalesAssist />,
    },
  };
  return (
    <>
      <Navbar params={props.match.path} />
      {products[props.match.params.name].component}
      <Footer />
    </>
  );
};

export default Index;
