import {TenantInfoModel} from "./tenantInfo.model";
import {FeatureSet} from "../../utils/FeatureSet";


export class NewTenantModel{
    public tenantInfo: TenantInfoModel;
    public feature: FeatureSet;
    public otpCode: string;
    constructor(props: any) {
        this.tenantInfo = this.getTenantInfo(props);
        this.feature = props.feature;
        this.otpCode = props.otpCode;
    }
    getTenantInfo(props: any){
        return new TenantInfoModel(props);
    }
}