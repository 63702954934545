import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import { craftsmanList } from "../../content/Team";
import FullScreenDialog from "../../components/DialogBox";

const useStyles = makeStyles((theme) => ({
  root: {
    // minHeight: 955,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.between("xs", "sm")]: {
      minHeight: "auto",
    },
  },
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    margin: "40px 0",
    [theme.breakpoints.down("sm")]: {
      border: "1px solid black",
      padding: 16,
      borderRadius: 5,
      fontSize: 20,
      margin: "28px 0",
    },
  },
  craftsman: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    paddingTop: 15,
  },
  craftsmanName: {
    marginTop: 12,
    fontWeight: theme.typography.semiBold,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  craftsmanPosition: {
    padding: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      padding: 6,
    },
  },
  cardPosition: {
    height: 215,
  },
  gridContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  profile: {
    display: "flex",
    justifyContent: "center",
    height: 100,
    width: "100%",
    borderRadius: 50,
  },
  profileImg: {
    borderRadius: "3px",
    "&:hover": {
      transform: "scale(1.1)",
      transition: ".3s",
      boxShadow: "6px 6px 15px #aaaaaa",
    },
    [theme.breakpoints.down("sm")]: {
      boxShadow: "0px 0px 2px 0px",
    },
  },
  socials: {
    marginRight: 15,
  },

  arrowStyles: {
    color: "#00000081",
    position: "absolute",
    zIndex: 2,
    cursor: "pointer",
    backgroundColor: "transparent",
    display: "none",
    top: "calc(25% - 15px)",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      top: "calc(18% - 15px)",
    },
  },
  carousel: {
    backgroundColor: "white",
    height: 160,
  },
}));

const CraftsMen = () => {
  const {
    root,
    wrapper,
    title,
    craftsman,
    craftsmanName,
    craftsmanPosition,
    cardPosition,
    profile,
    profileImg,
    gridContainer,
  } = useStyles();
  // const matches = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const [open, setOpen] = React.useState(false);
  const [craftmanId, setCraftmanId] = React.useState("");

  const handleClickOpen = (id) => {
    setOpen(true);
    setCraftmanId(id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const card = (craftman) => {
    return (
      <Grid
        item
        sm={3}
        xs={6}
        lg={3}
        className={cardPosition}
        key={craftman.id}
      >
        <div className={profile} onClick={() => handleClickOpen(craftman.id)}>
          <img
            src={craftman.source}
            alt={craftman.name}
            style={{ width: 100 }}
            className={profileImg}
          />
        </div>
        <div className={craftsmanName}>{craftman.name}</div>
        <div className={craftsmanPosition}>{craftman.position}</div>
      </Grid>
    );
  };

  return (
    <div className={root}>
      <div className={wrapper}>
        <h1 className={title}>Our Team</h1>
        <div className={craftsman}>
          <div className={gridContainer}>
            <Grid container>
              {craftsmanList &&
                craftsmanList.map((craftman) => {
                  return (
                    <>
                      {card(craftman)}
                      <FullScreenDialog
                        open={open}
                        handleClose={handleClose}
                        craftmanId={craftmanId}
                      />
                    </>
                  );
                })}
            </Grid>
          </div>
        </div>

        <br />
        <br />
      </div>
    </div>
  );
};

export default CraftsMen;
