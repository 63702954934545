export const customerFeedbackList = [
	{
		id: 0,
		customerCompany: "Croma",
		categoryLabel: "ELECTRONICS",
		customerFeedback: `“ CASA helped us to go beyond traditional marketing and helped us to create more than 10 million loyal customers “`,
		customerPointOfContact: "Kapil Budukh",
		customerPointOfContactPosition: "General Manager -Marketing,Croma",
		videoUrl: "https://www.youtube.com/watch?v=3k44UF3hBTk",
		videoImageSource: require("../assets/Testimonial/pexels-andrea-piacquadio-842567-1.png"),
		customerLogo: require("../assets/Testimonial/Image 1-1.png"),
	},
];

export const customerTestimonialsList = [
	{
		id: 0,
		customerCompany: "Vivo",
		categoryLabel: "ELECTRONICS",
		customerTestimonial: `“ We have been associated with Casa team from last 8 months and the experience has been amazing so far. Their technology solutions are well formulated and thought through and the team is very proactive when it comes to brain storming sessions or queries“`,
		customerPointOfContact: "Mr.Sanchit Arora",
		customerPointOfContactPosition: `Head CRM EBO Vivo`,
		customerLogo: require("../assets/Testimonial/rsz_1rsz_vivo-2.png"),
	},
/*	{
		id: 1,
		customerCompany: "Lots Wholesale Solutions",
		categoryLabel: "RETAIL FMCG",
		customerTestimonial: `“ We have been associated with Casa team from last 5 months for CRM solution for our wholesale business and We experienced  amazing turn around time towards our all the queries so far.We found the team energetic and proactive during all the brain storming sessions or discussions.
        “`,
		customerPointOfContact: "Mr.Rajneesh",
		customerPointOfContactPosition: "GM Procurement &Operations CPWI",
		customerLogo: require("../assets/Testimonial/logo.png"),
	},*/
	{
		id: 2,
		customerCompany: "Ample Technologies",
		categoryLabel: "ELECTRONICS",
		customerTestimonial: `“ We are very have happy to work with Ajira team. They have been able to suggest meaningful solutions with their knowledge in system integration and CRM. “`,
		customerPointOfContact: "Mr.Ravi Shrivas",
		customerPointOfContactPosition: "Manager Ecommerce",
		customerLogo: require("../assets/Testimonial/Screenshot_2020-09-23_at_3.52.21_PM-removebg-preview.png"),
	},
];
