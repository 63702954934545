import React from "react";
import { makeStyles } from "@material-ui/core";
import HeroButton from "../../../components/heroBtn";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#f2f2f2",
    padding: theme.spacing(8),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3),
      marginTop: 0,
      height: "auto",
    },
    height: 240,
    marginTop: 40,
  },
  caption: {
    textAlign: "center",
    marginBottom: 25,
    fontSize: 32,
    width: "66%",
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      width: "80%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
      width: "80%",
    },

    fontWeight: theme.typography.semiBold,
  },
  title: {
    [theme.breakpoints.down("xs")]: {
      fontSize: 26,
    },
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  demo: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "space-evenly",
    },
  },
}));
const DemoSection = (props) => {
  const { demo, container, caption } = useStyles();
  const { content } = props;
  return (
    <div className={container}>
      <div className={demo}>
        <div className={caption}>{content.caption}</div>
        <HeroButton
          onClick={() => {
            window.ml_account("webforms", "5211053", "u6u0x4", "show");
          }}
        >
          {content.button}
        </HeroButton>
      </div>
    </div>
  );
};

export default DemoSection;
