import { List, ListItem, makeStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  link: {
    color: "inherit",
    textDecoration: "none",
    display: "block",
    width: "100%",
  },
  list: {
    paddingLeft: 0,
    width: "100%",
    paddingBottom: "3px !important",
    paddingTop: "3px !important",
    [theme.breakpoints.down("xs", "sm")]: {
      width: "100%",
    },
  },
  entryName: {
    [theme.breakpoints.down("xs", "sm")]: {
      padding: "15px 10px",
      width: "100%",
    },
  },
  listContainer: {
    width: "100%",
  },
}));
const ListOfLinks = (props) => {
  const { link, list, listContainer, entryName } = useStyles();
  const { details } = props;
  return (
    <List className={listContainer}>
      {details.map((entry) => (
        <ListItem key={entry.id} className={list}>
          <span className={entryName}>
            {entry.link ? (
              <Link key={entry.id} className={link} to={entry.link}>
                {entry.name}
              </Link>
            ) : (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={entry.href}
                className={link}
              >
                {entry.name}
              </a>
            )}
          </span>
        </ListItem>
      ))}
    </List>
  );
};

export default ListOfLinks;
