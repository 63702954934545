import React from "react";
import { Typography, Chip, Grid, makeStyles } from "@material-ui/core";
import { customerTestimonialsList } from "../../content/Testimonial";

const useStyles = makeStyles((theme) => ({
  customerTestimonialContainer: {
    padding: 30,
    display: "flex",
    justifyContent: "center",
  },
  customerTestimonialGridContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 15,
  },
  customerTestimonialCard: {
    [theme.breakpoints.down("sm")]: {
      height: 294,
    },
    height: 315,
    width: 324,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  customerCompany: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    fontSize: 18,
    fontWeight: "bolder",
    textAlign: "center",
  },
  customerCategoryLabel: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    borderRadius: 0,
    fontSize: 12,
    background: "#FFFFFF",
  },
  customerTestimonials: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
    fontSize: 14,
    fontWeight: theme.typography.semiBold,
    textAlign: "center",
  },
  customerTestimonialsPointOfContactContainer: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    fontWeight: theme.typography.semiBold,
    fontSize: 12,
    textAlign: "center",
  },
  customerLogo: {
    width: 100,
  },
  knowMoreLink: {
    color: "#F07663",
    fontWeight: "bold",
    fontSize: 14,
  },
}));

const CustomerTestimonial = () => {
  const classes = useStyles();
  const {
    customerTestimonialContainer,
    customerTestimonialGridContainer,
    customerTestimonialCard,
    customerCompany,
    customerCategoryLabel,
    customerTestimonials,
    customerTestimonialsPointOfContactContainer,
    customerLogo,
  } = classes;

  return (
    <Grid container className={customerTestimonialContainer}>
      {customerTestimonialsList &&
        customerTestimonialsList.map((testimonials) => {
          return (
            <Grid
              key={testimonials.id}
              lg={4}
              md={4}
              sm={6}
              xs={12}
              item
              className={customerTestimonialGridContainer}
            >
              <div className={customerTestimonialCard}>
                <Typography className={customerCompany}>
                  {testimonials.customerCompany}
                </Typography>
                <Chip
                  label={testimonials.categoryLabel}
                  variant="outlined"
                  className={customerCategoryLabel}
                />
                <Typography className={customerTestimonials}>
                  {testimonials.customerTestimonial}
                </Typography>
                <Typography
                  className={customerTestimonialsPointOfContactContainer}
                >
                  {`${testimonials.customerPointOfContact},${testimonials.customerPointOfContactPosition}`}
                </Typography>
                <img
                  className={customerLogo}
                  src={testimonials.customerLogo}
                  alt="Customer Logo"
                />
              </div>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default CustomerTestimonial;
