import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
// import Title from "../Title";
import clsx from "clsx";

export default function CrossBurger({ newState }) {
  let iconWidth = 23;
  let iconHeight = iconWidth * 0.75;

  const useStyles = makeStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      // minHeight: 100,
      paddingRight: 15,
    },
    isActive: {
      "&> span:nth-child(1), &> span:nth-child(4)": {
        opacity: 0,
      },

      "&> span:nth-child(2)": {
        transform: "rotate(45deg)",
      },

      "&> span:nth-child(3)": {
        top: iconWidth / 3.2,
        transform: "rotate(-45deg)",
      },
    },
    menuIcon: {
      position: "relative",
      width: iconWidth,
      height: iconHeight,
      display: "inline-block",

      "&> span": {
        position: "absolute",
        zIndex: 1310,
        height: iconWidth / 10,
        width: 25,
        backgroundColor: "gray !important",
        transition: "all 250ms ease-in-out",
        transform: "rotate(0deg)",
        boxShadow: "0px 1px 1px rgba(255,255,255,0.6)",
      },

      "&> span:nth-child(1)": {
        top: iconWidth / 32,
      },

      "&> span:nth-child(2), > span:nth-child(3)": {
        top: iconWidth / 3.2,
      },

      "&> span:nth-child(4)": {
        top: iconWidth * 0.625,
      },
    },
    menuButton: {
      "&:hover span, &:focus span, &:active span": {
        // background: "#ffffff",
      },
    },
  });

  const classes = useStyles();

  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(newState);
  }, [newState]);
  return (
    <>
      <div className={classes.container}>
        <div className={classes.menuButton} onClick={() => setActive(!active)}>
          <div
            className={clsx({
              [classes.menuIcon]: true,
              [classes.isActive]: active,
            })}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </>
  );
}
