import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-brands-svg-icons';
import * as SolidIcon from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';

import {
  FooterDetails,
  FooterTitle,
  MobileFooterDetails,
} from '../content/Home';
import SliderAccordion from './accordion';
import Brand from './Brand';
import ListOfLinks from './listOfLinks';
import MainContainer from './mainContainer';

// import SliderAccordion from "./accordion";
library.add(
  Icons.faFacebook,
  Icons.faTwitterSquare,
  Icons.faLinkedin,
  Icons.faYoutubeSquare,
  Icons.faInstagram,
  Icons.faTwitter,
  Icons.faPinterest,
  Icons.faYoutube,
  SolidIcon.faEnvelope,
  SolidIcon.faPhoneAlt
);
const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#4A4A4A',
    color: '#FFFFFF',
    minHeight: 358,
    display: 'flex',
    paddingTop: 25,
    [theme.breakpoints.down('sm')]: {
      minHeight: 0,
      paddingTop: 10,
    },
  },
  business: {
    fontSize: 14,
    textAlign: 'center',
    fontWeight: theme.typography.semiBold,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  footerCaption: {
    paddingLeft: 50,

    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  container: {
    padding: '0 25px',
    maxWidth: 300,
    '@media (max-width:1024)': {
      margin: `${theme.spacing(3)}px 0`,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 260,
      display: 'flex',
      padding: 0,
      flexDirection: 'column',
      alignItems: 'center',
      margin: theme.spacing(1),
    },
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 90,
    },
  },
  image: {
    height: 90,
    [theme.breakpoints.down('sm')]: {
      height: '45px',
    },
  },
  iconsContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%',
      marginBottom: '10px',
    },
  },
  iconsGridContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    color: 'white',
    textDecoration: 'none',
    fontSize: 22,
    opacity: '100%',
    marginLeft: 20,
    borderRadius: '50%',
  },
  iconFb: {
    '&:hover': {
      color: '#16aabf',
    },
  },
  iconLinkedin: {
    '&:hover': {
      color: '#0e76a8',
    },
  },
  summary: {
    backgroundColor: '#707070',
  },
  expandIcon: {
    color: '#FFFFFF',
  },

  footerProductList: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingRight: 20,
  },

  footerProductListText: {
    fontSize: 20,
    fontFamily: 'Nunito',
  },
  contact:{
    listStyle:'none',
    paddingLeft:0,
    display: 'flex',
    flexDirection: 'column',
    gap:'.5rem'
  },
  contactItem:{
    display: 'flex',
    alignItems:'center',
    gap:'.75rem',
    whiteSpace:'nowrap',
    color:'white',
    textDecoration:'none',
    '&:hover':{
      textDecoration:'underline',
    }
  },
  contactIcon: {
    color: 'white',
    fontSize: 16,
  },
}));
const Footer = (props) => {
  const {
    footer,
    business,
    footerCaption,
    container,
    imageContainer,
    // image,
    iconsContainer,
    iconsGridContainer,
    icon,
    iconFb,
    iconLinkedin,
    summary,
    expandIcon,
    footerProductList,
    footerProductListText,
    contact,
    contactItem,
    contactIcon
  } = useStyles();
  const mobileMatcher = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState(null);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // const [loaderState, setLoaderState] = useState(false);
  return (
    <div className={footer} id="down">
      {
        <MainContainer>
          <Grid container>
            <Grid item xs={12} md={6}>
              <div className={footerCaption}>
                <div className={container}>
                  <div className={imageContainer}>
                    <Brand textColor='#fff' />
                  </div>
                  <br />
                  <Typography className={business}>{FooterTitle}</Typography>
                  <br />
                  <div className={iconsContainer}>
                    <Grid container className={iconsGridContainer}>
                      <Grid item xs={2} md={2}>
                        <a
                          href='https://www.linkedin.com/company/casa-ajira/'
                          className={clsx(icon, iconFb)}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <FontAwesomeIcon icon={['fab', 'linkedin']} />
                        </a>
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <a
                          href='https://www.facebook.com/CasaRetailAI'
                          className={clsx(icon, iconFb)}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <FontAwesomeIcon icon={['fab', 'facebook']} />
                        </a>
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <a
                          href='https://twitter.com/casaretail_ai'
                          className={clsx(icon, iconFb)}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <FontAwesomeIcon icon={['fab', 'twitter']} />
                        </a>
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <a
                          href='https://www.instagram.com/casa_retail_ai/'
                          className={clsx(icon, iconFb)}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <FontAwesomeIcon icon={['fab', 'instagram']} />
                        </a>
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <a
                          href='https://in.pinterest.com/casa_retail_ai/'
                          className={clsx(icon, iconFb)}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <FontAwesomeIcon icon={['fab', 'pinterest']} />
                        </a>
                      </Grid>
                      <Grid item xs={2} md={2}>
                        <a
                          href='https://www.youtube.com/channel/UCPVTpFMPq888hjJvi5kDttg'
                          className={clsx(icon, iconLinkedin)}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          <FontAwesomeIcon icon={['fab', 'youtube']} />
                        </a>
                      </Grid>
                    </Grid>
                  </div>
                  <div>
                    <ul className={contact} >
                      <li>
                        <span>
                        WorkEZ The Ark, 2nd Floor, 189, Rajiv Gandhi Salai, OMR, Sholinganallur, Chennai, Tamil Nadu 600119
                        </span>
                      </li>
                      <li>
                        <a href='mailto:casa-sales@ajirasoft.com'  target='_blank'
                          rel='noopener noreferrer' className={contactItem} >
                          <FontAwesomeIcon className={clsx(contactIcon)} icon={['fas', 'envelope']} />
                          casa-sales@ajirasoft.com
                        </a>
                      </li>
                      <li>
                        <a href="tel:9655941932"  target='_blank'
                          rel='noopener noreferrer' className={contactItem} >
                          <FontAwesomeIcon className={clsx(contactIcon)} icon={['fas', 'phone-alt']} />
                          9655941932
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              {mobileMatcher ? (
                <Container disableGutters>
                  {MobileFooterDetails.map((detail) => {
                    return (
                      <SliderAccordion
                        key={detail.id}
                        expanded={expanded === detail.id}
                        onAccordionChange={handleChange(detail.id)}
                        onClickDetail={() => {
                          setExpanded(null);
                        }}
                        accordionClasses={{
                          expandIcon,
                          summary,
                        }}
                        detail={detail}
                      />
                    );
                  })}
                </Container>
              ) : (
                <Grid container>
                  {FooterDetails.map((value) => (
                    <Grid key={value.id} item md={4}>
                      {value.id === 1 && (
                        <>
                          <Typography className={footerProductList}>
                            <strong className={footerProductListText}>
                              {value.summary}
                            </strong>
                          </Typography>
                          <ListOfLinks details={value.details} />
                        </>
                      )}
                      {value.id === 2 && (
                        <>
                          <Typography className={footerProductList}>
                            <strong className={footerProductListText}>
                              {value.summary}
                            </strong>
                          </Typography>
                          <ListOfLinks details={value.details} />
                        </>
                      )}

                      {value.id === 3 && (
                        <>
                          <Typography>
                            <strong className={footerProductListText}>
                              {value.summary}
                            </strong>
                          </Typography>
                          <ListOfLinks details={value.details} />
                        </>
                      )}
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
        </MainContainer>
      }
    </div>
  );
};

export default Footer;
