import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import Caption from "../../components/caption";
import { AwardList } from "../../content/Home";
import MainContainer from "../../components/mainContainer";

const useStyles = makeStyles((theme) => ({
  captionText: {
    padding: "10px 0",
    marginTop: 20,
  },

  contentContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
    // minHeight: 320,
  },
  awardsContainer: {
    height: 125,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      // minHeight: 150,
      minHeight: 200,
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "50%",
    margin: theme.spacing(2),

    [theme.breakpoints.down("xs")]: {
      height: "100%",
      margin: 0,
    },
  },
  image: {
    width: "30%",
    [theme.breakpoints.down("xs")]: {
      // width: 118,
      width: 90,
    },
  },
}));
const Awards = (props) => {
  const {
    contentContainer,
    image,
    imageContainer,
    awardsContainer,
    captionText,
  } = useStyles();
  return (
    <div className={contentContainer}>
      <MainContainer>
        <div>
          <Caption className={captionText}>Our Awards</Caption>
          <div className={awardsContainer}>
            <Grid container>
              {AwardList.map((award) => (
                <Grid item key={award.id} xs={6} sm={3}>
                  <div className={imageContainer}>
                    <img
                      className={image}
                      src={award.source}
                      alt={`Award ${award.id}`}
                    />
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default Awards;
