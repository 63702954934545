import { Box, Button, Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import styles from "./Steps.module.scss";
import {
  historicBillData,
  itemMasterData,
  storeMasterData,
  userListData,
} from "../../content/HistorialBill";
import { downloadFile } from "../../utils/downloadCsv";
import GetAppIcon from "@material-ui/icons/GetApp";
import HighlightIcon from "@material-ui/icons/Highlight";

const Steps = () => {
  const historicBillBlob = new Blob(historicBillData);
  const ItemMasterBlob = new Blob(itemMasterData);
  const StoreMasterBlob = new Blob(storeMasterData);
  const UserListBlob = new Blob(userListData);
  const productBlob = new Blob();

  return (
    <Box id={styles.generalChecklist}>
      <Box className={styles.header}>Welcome to CASA</Box>
      <Box className={styles.content}>
        <Card className={styles.card} elevation={0}>
          <Box className={styles.title}>STEP 1</Box>
          <Typography className={styles.subTitle}>
            To get started with your onboarding submit the following documents
          </Typography>
          <br />
          <Box>Congrats on choosing CASA as your CRM/CDP platform. As you are seeing this webpage, a whatsapp group would also have been created for you along with your SPOC from CASA. </Box>
          <Box>If you are using Ginesys POS, please remind your CASA POS to raise a ticket to get historical data</Box>
          <Box>If you are using Wondersoft POS, please ask your Wondersoft SPOC to provide URL to read historical data</Box>
          <Box>If you are using APX, please ask your APX SPOC for credentials for CASA to automatically read historical data</Box>
          <Box>If you are using any other POS, please use the format provided below for uploading historical bills</Box>
          <CardContent className={styles.masterList}>
            <Box>
              <Box className={styles.item}>1. Historic Bills</Box>
              <Button
                variant="outlined"
                className={styles.downloadButton}
                onClick={() =>
                  downloadFile(historicBillBlob, "Sample_Historic_Bill.csv")
                }
              >
                <GetAppIcon fontSize="small" /> Download Sample File
              </Button>
            </Box>
            <Box>
              <Box className={styles.item}>2. Item Master</Box>
              <Button
                variant="outlined"
                className={styles.downloadButton}
                onClick={() =>
                  downloadFile(ItemMasterBlob, "Sample_Item_Master.csv")
                }
              >
                <GetAppIcon fontSize="small" /> Download Sample File
              </Button>
            </Box>
            <Box>
              <Box className={styles.item}>3. Store Master</Box>
              <Button
                variant="outlined"
                className={styles.downloadButton}
                onClick={() =>
                  downloadFile(StoreMasterBlob, "Sample_Store_Master.csv")
                }
              >
                <GetAppIcon fontSize="small" /> Download Sample File
              </Button>
            </Box>
            <Box>
              <Box className={styles.item}>4. User List</Box>
              <Button
                variant="outlined"
                className={styles.downloadButton}
                onClick={() => downloadFile(UserListBlob, "Sample_User_List.csv")}
              >
                <GetAppIcon fontSize="small" /> Download Sample File
              </Button>
            </Box>
            <Box>
              <Box className={styles.item}>5. Product Hierarchy</Box>
              <Button
                variant="outlined"
                className={styles.downloadButton}
                onClick={() =>
                  downloadFile(productBlob, "Sample_Product_Hierarchy.csv")
                }
              >
                <GetAppIcon fontSize="small" /> Download Sample File
              </Button>
            </Box>
          </CardContent>
          <CardContent>
          <Box className={styles.title}>Why to Submit these documents</Box>
          <Typography className={styles.reasonToSubmit}>
              Allows CASA to understand customer buying patterns and its AI
              engines to determine actionable segments at store, region or brand
              level.Further understand product affinity to engage with customers
              on product they prefer
            </Typography>
          </CardContent>
        </Card>
        <Card className={styles.successCard} elevation={0}>
          <HighlightIcon fontSize="large" />
          You have successfully completed your First step towards CASA
        </Card>
        <Card className={styles.card} elevation={0}>
          <Box className={styles.title}>Why to Submit these documents</Box>
          <Typography className={styles.subTitle}>
            Module wise requirements
          </Typography>
          <CardContent className={styles.requirements}>
            <Card
              elevation={0}
              variant="outlined"
              className={styles.moduleContainer}
            >
              <Box className={styles.item}>Intelliclose</Box>
              <Box className={styles.module}>
                <Box className={styles.moduleItem}>List of roles</Box>
                <Box className={styles.moduleItem}>List of store managers</Box>
                <Box className={styles.moduleItem}>Lane list</Box>
                <Box className={styles.moduleItem}>Facebook and instagram</Box>
                <Box className={styles.moduleItem}>User hierarchy</Box>
              </Box>
            </Card>
            <Card
              elevation={0}
              variant="outlined"
              className={styles.moduleContainer}
            >
              <Box className={styles.item}>Campaign Delight</Box>
              <Box className={styles.module}>
                <Box className={styles.moduleItem}>Sender Id</Box>
                <Box className={styles.moduleItem}>Sender Mail Id</Box>
                <Box className={styles.moduleItem}>Sender Name</Box>
                <Box className={styles.moduleItem}>Register mobile number</Box>
              </Box>
            </Card>
            <Card
              elevation={0}
              variant="outlined"
              className={styles.moduleContainer}
            >
              <Box className={styles.item}>User management</Box>
              <Box className={styles.module}>
                <Box className={styles.moduleItem}>Role based permissions</Box>
              </Box>
            </Card>
            <Card
              elevation={0}
              variant="outlined"
              className={styles.moduleContainer}
            >
              <Box className={styles.item}>Retensee</Box>
              <Box className={styles.module}>
                <Box className={styles.moduleItem}>Loyalty Tier</Box>
                <Box className={styles.moduleItem}>
                  Points per 100 rs spent for each tier
                </Box>
                <Box className={styles.moduleItem}>
                  Loyalty as discount or MOP
                </Box>
                <Box className={styles.moduleItem}>
                  Point overrides when items are on discount
                </Box>
                <Box className={styles.moduleItem}>
                  Variable points based on category if possible
                </Box>
              </Box>
            </Card>
            <Card
              elevation={0}
              variant="outlined"
              className={styles.moduleContainer}
            >
              <Box className={styles.item}>Digital Receipt</Box>
              <Box className={styles.module}>
                <Box className={styles.moduleItem}>
                  Brand logo / Brand images
                </Box>
                <Box className={styles.moduleItem}>Website and click links</Box>
                <Box className={styles.moduleItem}>Social Media links</Box>
                <Box className={styles.moduleItem}>
                  Points overrides when items are on discount
                </Box>
                <Box className={styles.moduleItem}>
                  Variables points based on category if possible
                </Box>
              </Box>
            </Card>
            <Card
              elevation={0}
              variant="outlined"
              className={styles.moduleContainer}
            >
              <Box className={styles.item}>Personalized Web app</Box>
              <Box className={styles.module}>
                <Box className={styles.moduleItem}>
                  Header and footer dimensions
                </Box>
                <Box className={styles.moduleItem}>OR code if required</Box>
              </Box>
            </Card>
          </CardContent>
        </Card>
        <Card className={styles.successCard} elevation={0}>
          <HighlightIcon fontSize="large" />
          You have successfully Uploaded your documents!
        </Card>
      </Box>
      <Box className={styles.footer}>
        For further instructions, our team will be contacting you in the
        upcoming days.
      </Box>
    </Box>
  );
};

export default Steps;
