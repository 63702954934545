import React, {FC} from "react";
import casa_logo from "../../assets/Home/casa-retailai-dark-1@2x.png";
import './SignUp.css';
import {Button, CircularProgress, FormControl, InputLabel, MenuItem, Modal, Select} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    RETAIL_CATEGORY,
    RETAIL_SIZE,
    validateEmail,
    SCREEN_SIZE,
    validatePasswordPattern, createEsKey
} from "../../utils/signupUtils"
import _ from "lodash"
import {useHistory} from "react-router-dom";
import flipsellProduct from "../../assets/Products/flipSell/img.png";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {getOtpForEmail, validateAndCreateTenant} from "../../services/tenant-maintenance.service";
import Navbar from "../../components/navbar";
import {NewTenantModel} from "../../models/tenant/NewTenant.model";
import casa_path_background from "../../assets/Products/flipSell/Path 5311@2x.png";
import flispell_image from "../../assets/Products/flipSell/Component 8 – 1@2x.png";
import undraw_powerful from "../../assets/Products/flipSell/undraw_Powerful_re_frhr@2x.png";

const useStyles = makeStyles((theme) => ({
    formControl: {
        width: '100%',
        marginBottom: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },

}));
const EXISTING_TENANT_SUCCESS_MESSAGE = 'Feature has been successfully added to your account';
const NEW_TENANT_SUCCESS_MESSAGE = 'You have Successfully Signed up with CASA. You will get confirmation mail shortly!';

export const SignUp: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const [userData, setUserData] = React.useState({
        BusinessName: '',
        MobileNumber: '',
        EmailId: '',
        RetailType: '',
        RetailSize: ''
    });
    const [isSignUpDisabled, setIsSignUpDisabled] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [error, setError] = React.useState({message: 'ERROR_MESSAGE', status: 'none'})
    const [errorOTP, setErrorOTP] = React.useState({message: 'ERROR_MESSAGE', status: 'none'})
    const [signupFormState, setSignupFormState] = React.useState(0);
    const [userCredentials, setUserCredentials] = React.useState({Password: '', ConfirmPassword: '', OTP: ''});
    const [isSubmitDisabled, setIsSubmitDisabled] = React.useState(true);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = React.useState(false);
    const [existingTenant, setExistingTenant] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    function getRetailCategories() {
        return _.map(RETAIL_CATEGORY, (value, key) => {
            return (<MenuItem value={key}>{key}</MenuItem>);
        });
    }

    function getPasswordCriteria() {
        return (
            <div>
                <div style={{fontWeight: 900}}>Password Should Contain</div>
                [8 or more characters , 1 or more letters in Upper Case, 1 or more Special Character (!@#$&*), 1 or more
                numerals (0-9), 1 or more letters in Lower Case]
            </div>
        )
    }

    const OTP_ERROR = {
        1: "*Passwords confirmation did not match",
        2: getPasswordCriteria(),
        3: true
    }

    function getRetailSize() {
        return _.map(RETAIL_SIZE, (value, key) => {
            return (<MenuItem value={key}>{key}</MenuItem>);
        });
    }

    function onClickCasa() {
        history.push("/");
    }

    function isValidForm(userData: any) {
        return validateEmail(userData.EmailId) && !_.values(userData).some(x => _.isEmpty(x)) && userData.MobileNumber.length >= 10;
    }

    const onChangeData = (props: any) => {
        const updatedData = {
            ...userData,
            [props.target.name]: props.target.value
        }
        setUserData(updatedData);
        isValidForm(updatedData) ? setIsSignUpDisabled(false) : setIsSignUpDisabled(true);
    }

    const onChangeCredentials = (props: any) => {
        const updatedData = {
            ...userCredentials,
            [props.target.name]: props.target.value
        }
        setUserCredentials(updatedData);
        !_.values(updatedData).some(x => _.isEmpty(x)) || (existingTenant && !_.isEmpty(updatedData.OTP)) ? setIsSubmitDisabled(false) : setIsSubmitDisabled(true);
    }
    const onSubmitData = () => {
        setIsLoading(true);
        getOtpForEmail(userData.EmailId).then((response: any) => {
            response.json().then((result: any) => {
                if (response.status !== 200) {
                    const newErrorStatus = {
                        message: result.message,
                        status: "inherit"
                    };
                    setError(newErrorStatus);
                    setIsLoading(false);

                } else {
                    setExistingTenant(result.existingTenant);
                    const newSuccessMessage = result.existingTenant ? EXISTING_TENANT_SUCCESS_MESSAGE : NEW_TENANT_SUCCESS_MESSAGE;
                    setSuccessMessage(newSuccessMessage);
                    setIsLoading(false);
                    setSignupFormState(1);
                }
            })
        })
    }
    const getUserRequest = () => {
        return {
            ...userData,
            password: userCredentials.Password,
            feature: "flipbook",
            otpCode: userCredentials.OTP,
            role: "admin",
            EsKey: createEsKey(userData.BusinessName)
        }
    }
    const validateAndCreateAccount = () => {
        setIsLoading(true);
        const status: any = existingTenant? existingTenant: _.get(OTP_ERROR, validatePasswordPattern(userCredentials));
        if (_.isEqual(status, true)) {
            const existingStatus = {
                ...errorOTP,
                status: "none"
            };
            setErrorOTP(existingStatus);
            const Tenant = new NewTenantModel(getUserRequest());
            validateAndCreateTenant(Tenant).then(response => {
                if (response.status !== 200) {
                    response.json().then((result: any) => {
                        const newErrorStatus = {
                            message: result.message,
                            status: "inherit"
                        };
                        setErrorOTP(newErrorStatus);
                        setIsLoading(false);
                    })
                } else {
                    setIsLoading(false);
                    setIsSuccessModalOpen(true);
                }
            })
        } else {
            const newErrorStatus = {
                message: status,
                status: "inherit"
            };
            setErrorOTP(newErrorStatus);
            setIsLoading(false);
            setSignupFormState(3);
        }
    }
    const onClickBack = () => {
        const previousState = signupFormState - 1;
        setSignupFormState(previousState);
    };

    const signupFormPage = () => {
        return (
            <div>
                <div className={'flipsell-signup-form-container'}>
                    <div>
                        <img className={'casa-logo'} src={casa_logo} alt={'casa-logo'}
                             onClick={() => onClickCasa()}/>
                    </div>
                    <div className={'flipsell-signup-form-title'}>
                        Try Flipsell free for 14 days
                    </div>
                    <div className={'flipsell-signup-form-page'}>
                        <div className={'form-mandatory-field-prompt'}>
                            All fields are mandatory
                        </div>
                        <form>
                            <input
                                type='text'
                                className={'signup-input-field'}
                                name={'BusinessName'}
                                value={userData.BusinessName}
                                placeholder={'Business Name'}
                                onChange={(props) => onChangeData(props)}
                                required={true}
                            />
                            <PhoneInput
                                country={'in'}
                                onlyCountries={['in']}
                                countryCodeEditable={true}
                                value={userData.MobileNumber}
                                placeholder={'Mobile number'}
                                disableCountryCode={true}
                                containerClass={'signup-input-field-phone'}
                                inputClass={'signup-input-phone'}
                                inputProps={{
                                    required: false
                                }}
                                onChange={(phone) => {
                                    let props = {target: {name: "MobileNumber", value: phone}};
                                    if (_.isEqual(phone, '91')) {
                                        props.target.value = "";
                                    }
                                    onChangeData(props);
                                }}
                            />
                            <input
                                type='text'
                                className={'signup-input-field'}
                                name={'EmailId'}
                                value={userData.EmailId}
                                placeholder={'Email address'}
                                onChange={(props) => onChangeData(props)}
                                required={true}
                            />
                            <div className={'signup-question'}>
                                Which category of Retail do you operate in?
                            </div>
                            <FormControl variant="outlined" className={classes.formControl}
                                         size={SCREEN_SIZE}>
                                <InputLabel id="retail-select-outlined-label">Select Any</InputLabel>
                                <Select
                                    id="retail-simple-select-outlined"
                                    label="Select any"
                                    required={true}
                                    value={userData.RetailType}
                                    name={'RetailType'}
                                    onChange={(props) => onChangeData(props)}
                                >
                                    {getRetailCategories()}
                                </Select>
                            </FormControl>
                            <div className={'signup-question'}>
                                What is the size of your retail chain?
                            </div>
                            <FormControl variant="outlined" className={classes.formControl}
                                         size={SCREEN_SIZE}>
                                <InputLabel id="retail-size-select-outlined-label">Select Any</InputLabel>
                                <Select
                                    id="retail-size-simple-select-outlined"
                                    label="Select any"
                                    value={userData.RetailSize}
                                    required={true}
                                    name={'RetailSize'}
                                    onChange={(props) => onChangeData(props)}
                                >
                                    {getRetailSize()}
                                </Select>
                            </FormControl>
                            <div className={'terms-and-conditions'}>
                                By signing up, you are agreeing to the <span
                                style={{
                                    color: "#F07663",
                                    textDecoration: "underline"
                                }}>CASA terms of service</span>.
                                View our <span
                                style={{
                                    color: "#F07663",
                                    textDecoration: "underline"
                                }}>privacy policy</span>.
                            </div>
                            <div className={'error-prompt-message'} style={{display: `${error.status}`}}>
                                * {error.message}
                            </div>
                            <div className={'signup-button-container'}>
                                <button type={"button"} disabled={isSignUpDisabled} className={'sign-up'}
                                        onClick={onSubmitData}>Sign up for
                                    free
                                </button>
                            </div>
                            <div className={'already-registered-signup'}>
                                Already registered? <span
                                style={{
                                    color: "#F07663",
                                    textDecoration: "underline"
                                }}> Sign into existing account</span>.
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    const otpValidatePage = () => {
        return (
            <div>
                <div className={'flipsell-signup-form-container'}>
                    <div>
                        <img className={'casa-logo'} src={casa_logo} alt={'casa-logo'}
                             onClick={() => onClickCasa()}/>
                    </div>
                    <div className={'flipsell-signup-form-title'}>
                        Try Flipsell free for 14 days
                    </div>
                    <div className={'flipsell-signup-form-page'}>
                        <div className={'otp-header'}>
                            OTP will be sent to
                        </div>
                        <div className={'signup-form-mailid'}>
                            {userData.EmailId}
                        </div>
                        <form>
                            <input
                                type='text'
                                className={'signup-input-field'}
                                name={'OTP'}
                                placeholder={'Enter OTP'}
                                onChange={(props) => onChangeCredentials(props)}
                                required={true}
                            />
                            <Button
                                className={'resend-otp'}
                                onClick={onSubmitData}>Resend OTP
                            </Button>
                            {(!existingTenant &&
                            <>
                                <input
                                    type='password'
                                    className={'signup-input-field'}
                                    name={'Password'}
                                    placeholder={'Enter Password'}
                                    onChange={(props) => onChangeCredentials(props)}
                                    required={true}
                                />
                                <input
                                    type='password'
                                    className={'signup-input-field'}
                                    name={'ConfirmPassword'}
                                    placeholder={'Confirm Password'}
                                    onChange={(props) => onChangeCredentials(props)}
                                    required={true}
                                />
                            </>
                            )}

                            <div className={'error-prompt-message'} style={{display: `${errorOTP.status}`}}>
                                {errorOTP.message}
                            </div>
                            <div className={'submit-button-container'}>
                                <Button type={"button"} className={'back-button'}
                                        onClick={onClickBack}>Back
                                </Button>
                                <button type={"button"} className={'submit-tenant'} disabled={isSubmitDisabled}
                                        onClick={validateAndCreateAccount}>Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    const successPage = () => {
        return (
            <div className={'modal-container'}>
                <div className={'modal-right-gradient'}>
                    <div className={'modal-casa-logo-item'}>
                        <img src={casa_logo} alt={'Casa Logo'} className={'modal-casa-logo'}/>
                    </div>
                    <div className={'modal-flipsell-logo-item'}>
                        <img src={casa_path_background} alt={'flipsell-background'} className={'modal-flipsell-logo'}/>
                        <img src={flispell_image} alt={'Flipsell-product'} className={'modal-flipsell-image'}/>

                    </div>
                </div>
                <div className={'modal-left-gradient'}>
                    <div className={'modal-undraw-powerful-item'}>
                        <img src={undraw_powerful} alt={'powerful'} className={'modal-undraw-powerful'}/>
                    </div>
                    <div style={{position: 'relative'}}>
                        <div className={'Thankyou-card'}>Thankyou for choosing CASA Flipsell</div>
                        <div className={'Registered-with-casa'}>{successMessage}
                        </div>
                        <div className={'page-turn-container'}>
                            <div className={'curl'}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div className={'Mobile-view-navigation'}><Navbar/></div>
            <div className={'signup-container'}>
                <div className={'flipbook-details-screen'}>
                    <div className={'flipsell-oneline-lg'}>
                        Feel your customer touch points With our Powerful digital catalogue Flipsell
                    </div>
                    <div className={'flipsell-oneline-sm'}>
                        A shareable high-quality digital catalog to showcase products across digital platforms
                    </div>
                    <div className={'flipsell-product-image-container'}>
                        <img className={'flipsell-product-image'} src={flipsellProduct} alt={'Flipsell-product'}/>
                    </div>
                </div>
                {isLoading ? (
                        <div style={{margin: 'auto'}}>
                            <CircularProgress color="primary"/>
                        </div>
                    ) :
                    (
                        <>
                            {signupFormState === 0 && signupFormPage()}
                            {signupFormState === 1 && otpValidatePage()}
                        </>
                    )
                }
            </div>
            <Modal
                open={isSuccessModalOpen}
                onClose={() => {
                    setIsSuccessModalOpen(false)
                }}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
            >
                {successPage()}
            </Modal>
        </div>
    );
}
