import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    background:
      "linear-gradient(0deg, rgb(245, 212, 212) 0%, rgb(255, 255, 255) 50%)",
    // background:
    //   "linear-gradient(0deg, #FFEBEB 0%, #FFFFFF 50%) 0% 0% no-repeat",
    padding: theme.spacing(2),
    height: 250,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  caption: {
    fontSize: 20,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      marginBottom: "60px",
    },
    // fontWeight: theme.typography.semiBold,
  },
  title: {
    fontSize: 30,
    color: "#F07663",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 18,
    },
  },
  knowYourplatform: {
    textAlign: "center",
  },
}));
const AboutPlatform = (props) => {
  const { knowYourplatform, container, caption, title } = useStyles();
  const { content } = props;

  return (
    <div className={container}>
      <div className={knowYourplatform}>
        <h1 className={title}>{content.title}</h1>
        <div className={caption}>{content.caption}</div>
      </div>
    </div>
  );
};

export default AboutPlatform;
