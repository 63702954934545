import React from 'react';
// import Blogs from './Blogs';
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

const BlogsMain = () => {

    return (
        <div>
          <Navbar/>
          {/*<Blogs/>*/}
          <iframe style={{width: '100%', height: '100rem'}} title={'casa-blog'} src="https://casaretailai.blogspot.com/"></iframe>
          <Footer/>
        </div>
    )
}

export default BlogsMain;
