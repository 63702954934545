import { Grid, makeStyles, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";
import { openings } from "../../content/careers";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "'Nunito'",
    padding: "5%  0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: "9%  0",
    },
  },
  title: {
    fontSize: 32,
    margin: 0,
    fontWeight: 900,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  gridContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: 28,
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  paperTitle: {
    backgroundColor: "#4a5568",
    padding: 12,
    textAlign: "center",
    color: "white",
    fontSize: 32,
    fontWeight: 900,
    borderStartStartRadius: 4,
    borderStartEndRadius: 4,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  jobLocation: {
    fontSize: 13,
    margin: 0,
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  jobs: {
    textAlign: "center",
    padding: "20px 0",
  },
  jobTitle: {
    margin: 8,
    [theme.breakpoints.down("sm")]: {
      fontSize: 17,
    },
  },
  totalRole: {
    textDecoration: "none",
    color: "black",
  },
}));

const Openings = (props) => {
  const {
    root,
    title,
    gridContainer,
    grid,
    paperTitle,
    jobLocation,
    jobs,
    totalRole,
    jobTitle,
    paper,
  } = useStyles();
  return (
    <>
      <div className={root} id="openingsSection">
        <h1 className={title}>Current Openings</h1>
        <Grid container className={gridContainer}>
          {openings.map((opening, index) => (
            <Grid item sm={4} xs={12} lg={4} key={opening.id} className={grid}>
              <Paper elevation={8} className={paper}>
                <div className={paperTitle}>{opening.title}</div>
                <div className={jobs}>
                  {opening.jobs.map((job) => (
                    <Link to={job.path} className={totalRole}>
                      <h3 className={jobTitle}>{job.role}</h3>
                      <p className={jobLocation}>{opening.location}</p>
                    </Link>
                  ))}
                </div>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default Openings;
