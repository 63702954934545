import {TenantBrandBusinessModel} from "./business/TenantBrandBusiness.Model";
import {MetaFieldModel} from "./MetaField/MetaField.model";


export class TenantBrandModel {
    public name: string;
    public businesses: TenantBrandBusinessModel[];
    public metaField: MetaFieldModel;
    constructor(props: any) {
        this.name = props.BusinessName;
        this.businesses = this.getBrandBusiness(props);
        this.metaField = this.getMetaField(props);
    }
    getBrandBusiness(props: any){
        return [new TenantBrandBusinessModel(props)];
    }
    getMetaField(props: any){
        return new MetaFieldModel(props);
    }
}
