export const bannerContent = {
  caption: "Easily create your product's digital footprint",
  medium: "PIMkart",
  content: `Listing products on your eCommerce site and various marketplaces is a breeze`,
  button: "Get In Touch",
  source: require("../../assets/Products/pimkart/undraw_social_dashboard_k3pt.svg"),
};

export const platformContent = {
  title:
    "List your products on various marketplaces, facebook shop, Instashop and various channels",
  caption:
    "Digitally master your product in one location and publish to channels in a snap",
};

export const productCard = [
  {
    id: 1,
    title: "Consistent Product Experience",
    list: [
      "Master the product's digital footprint in a central location and provide a consistent experience across channels",
      // "Interact live with customers ",
      // "Create personalized shopping experiences",
    ],
  },
  {
    id: 2,
    title: "Ease of listing",
    list: [
      "List easily on various marketplaces by transforming content and images as per the channel's requirement",
      // "Push conversations to conversion",
    ],
  },
  {
    id: 3,
    title: "Ensure Product Quality",
    list: [
      "Built in maker-checker workflows to ensure that products are published after they have been certified",
      // "Automatically re-target previous and new shoppers ",
    ],
  },
];

export const productTabList = [
  { id: 1, name: "Ease of product mastering" },
  { id: 2, name: "Ease of digitization" },
  { id: 3, name: "Ease of delivery" },
];

export const productInfo = [
  {
    id: 1,
    source: require("../../assets/Products/pimkart/spotlight__monochromatic.svg"),
    content: {
      title: "Ease of product mastering",
      details:
        "Pimkart integrates seamlessly with leading ERPs to directly obtain product information as soon as it is entered in ERP. Newer products are highlighted and can be mastered with ease",
    },
  },
  {
    id: 2,
    source: require("../../assets/Products/pimkart/undraw_web_shopping_dd4l.svg"),
    content: {
      title: "Ease of digitization",
      details:
        "Create product hierarchies suited to a digital experience. Create your own attributes for various categories. Easy image reference and storage",
    },
  },
  {
    id: 3,
    source: require("../../assets/Products/pimkart/fast_working.svg"),
    content: {
      title: "Ease of delivery",
      details:
        "Pimkart pushes digital product information as per the needs of each marketplace or channel minimizing the work that needs to be done by you. Further tools in Pimkart ensure that content such as images are rendered in a size applicable to the marketplace",
    },
  },
];

export const demoContents = {
  caption: "It is time to connect with consumers seamlessly",
  button: "Book a Demo",
};

export default {
  bannerContent,
  platformContent,
  productCard,
  productTabList,
  productInfo,
};
