import React from 'react';

import styles from './UpgradedModules.module.scss';

const UpgradedModules = ({ logo, title, subtitle, points, background }) => {
  return (
    <div id={styles.upgradedModule} item md={4} sm={6} xs={12}>
      <div className={styles.moduleHeader}>
        <div className={styles.moduleImage}>
          <img src={logo} alt='title' />
        </div>
        <div>
          <h6 className={styles.moduleTitle}>{title}</h6>
          <p className={styles.moduleDescription}>{subtitle}</p>
        </div>
      </div>
      <div className={styles.divider} />
      <ul className={styles.points}>
        {points.map((value) => (
          <li>{value}</li>
        ))}
      </ul>
    </div>
  );
};

export default UpgradedModules;
