import React from "react";
import {
  Container,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import HeroButton from "../../../components/heroBtn";
// import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  boldTitle: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
    fontSize: 40,
    lineHeight: 1.2,
    // width: "71%",
    fontFamily: "'Nunito'",
    textShadow: "0px 1px, 1px 0px, 1px 1px",
    letterSpacing: "1px",

    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
      fontSize: 18,
      textShadow: "0px 0px, 0px 0px, 0px 1px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      textAlign: "left",
      fontSize: 30,
    },
  },
  titleSubtext: {
    fontWeight: theme.typography.semiBold,
    color: theme.palette.primary.main,
    fontSize: 40,
    lineHeight: 1.3,
    // marginTop: 28,
    margin: 8,
    textShadow: "0px 1px, 1px 0px, 1px 1px",

    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
      fontSize: 20,
      margin: "14px 8px 8px 8px",
    },
    [theme.breakpoints.between("sm", "md")]: {
      textAlign: "left",
      fontSize: 30,
    },
  },
  image: {
    // height: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "200px",
    },
  },
  pageCaption: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  bannerInfo: {
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  alignImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "47%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "51%",
    },
  },
  bannerImage: {
    height: 390,
    [theme.breakpoints.down("sm")]: {
      width: 210,
    },
  },
  learnMore: {
    marginTop: "24px",
    fontSize: "12px",
    width: "85%",

    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: "20px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginTop: "20px",
      display: "flex",
      width: "100%",
      justifyContent: "left",
    },
  },
  root: {
    height: 430,
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.between("xs", "sm")]: {
      marginTop: 30,
      height: "auto",
    },
  },
  aboutContent: {
    marginTop: "12px",
    fontSize: "25px",
    width: "100%",
    fontFamily: "'Nunito'",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
      textAlign: "center",
      fontSize: 16,
    },
  },
}));
const Banner = (props) => {
  const {
    boldTitle,
    image,
    pageCaption,
    titleSubtext,
    alignImage,
    learnMore,
    bannerInfo,
    root,
    aboutContent,
  } = useStyles();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const { content } = props;
  return (
    <Container className={root}>
      <Grid
        container
        direction={matches ? "column-reverse" : null}
        alignItems={matches && "center"}
      >
        <Grid item sm={6}>
          <div className={pageCaption}>
            <div className={bannerInfo}>
              <Typography variant="h3" className={titleSubtext}>
                {content.medium}
              </Typography>

              <Typography variant="h3" className={boldTitle}>
                {content.caption}
              </Typography>

              <Typography className={aboutContent}>
                {content.content}
              </Typography>

              <div className={learnMore}>
                <HeroButton
                  onClick={() =>
                    window.ml_account("webforms", "5211053", "u6u0x4", "show")
                  }
                >
                  <p style={{ fontSize: "14px", fontWeight: 600 }}>
                    {content.button}
                  </p>
                </HeroButton>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item sm={6} className={alignImage}>
          <div style={{ height: "80%" }}>
            <img
              src={content.source}
              className={image}
              alt="Undraw-Outline-Shopping-ga73"
              style={{ height: "100%", width: "100%" }}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Banner;
