import { Drawer, List, ListItem, makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Products, ProductsSummary } from '../content/Home';
import SliderAccordion from './accordion';
import Brand from './Brand';
import CrossBurger from './CrossBurger';
import ProductsBox from './productsBox';

// import MenuIcon from "@material-ui/icons/Menu";
// import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  menu: {
    boxShadow: "none",
    height: "calc(100% - 88px)",
    [theme.breakpoints.between("xs", "sm")]: {
      background: "white !important",
      color: "black !important",
      fontWeight: "500 ",
      // width: "72%",
      fontSize: "18px",
      top: "56px !important",
      height: "calc(100% - 60px)",
    },
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontWeight: "500",
    fontSize: "16px",
    width: "100%",
    "&:hover span": {
      color: "#F07663 !important",
    },
  },
  summary: {
    padding: 0,
    border: "none",
    outline: "none",
  },
  expandIcon: {
    color: "black !important",
  },
  accordion: {
    fontFamily: theme.typography.fontFamily,
    border: "none",
  },
  adjustHeight: {
    minHeight: 60,
    fontSize: "18px",

    // fontWeight: 600,
  },
  closeIconStyle: {
    marginLeft: "-90px",
    fontSize: "2rem",
  },
  menuIconStyle: {
    position: "fixed !important",
    zIndex: 1310,
    color: "#9E9E9E",
    fontSize: "1.8rem",
  },
  imageLogoContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("xs", "sm")]: {
      // width: 74,
    },
  },
  imageLogo: {
    // width: "100%",
    height: 40,
    marginBottom: 10,
  },
  menuContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "60px",
    background: "white",
    marginLeft: "15px",
    zIndex: 1210,
    [theme.breakpoints.down("xs", "sm")]: {
      height: "0px",
    },
  },
  mobileMenuContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "60px",
    marginLeft: "15px !important",
    zIndex: 1210,
  },

  // imageLogoHide: {
  //   display: "none",
  // },
  listItemTextStyle: {
    fontSize: "18px",
    // fontWeight: 600,
  },
}));

const MobileMenu = (props) => {
  const {
    menu,
    link,
    summary,
    expandIcon,
    accordion,
    // imageLogoContainer,
    // imageLogo,
    adjustHeight,
    // imageLogoHide,
    menuContainer,
    mobileMenuContainer,

    menuIconStyle,
  } = useStyles();

  const [bottom, setBottom] = useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setBottom(open);
  };
  const [expanded, setExpanded] = useState(null);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleDetail = () => {
    setBottom(false);
    setExpanded(null);
  };

  return (
    <div className={bottom ? mobileMenuContainer : menuContainer}>
      <Brand logoWidth={90} />
      <Typography align="right">
        <Button
          disableRipple
          aria-controls="mobileMenu"
          aria-haspopup="true"
          onClick={toggleDrawer("bottom", !bottom)}
        >
          <CrossBurger className={menuIconStyle} newState={bottom} />
        </Button>
      </Typography>

      <Drawer
        anchor={"up"}
        open={bottom}
        transitionDuration={1000}
        onClose={toggleDrawer("bottom", false)}
        onOpen={toggleDrawer("bottom", true)}
        classes={{ paperAnchorUp: menu }}
        ModalProps={{
          BackdropProps: {
            invisible: true,
          },
        }}
      >
        <div
          role="presentation"
          onClick={toggleDrawer("bottom", false)}
          onKeyDown={toggleDrawer("bottom", false)}
        >
          <List>
            {props.items.map((text) => (
              <ListItem
                disableRipple={text.name === "Solutions"}
                button
                key={text.id}
                className={adjustHeight}
              >
                {text.name === "Blog" && (
                  <a href="https://blogs.casaretail.ai/" className={link}>
                    {" "}
                    Blogs
                  </a>
                )}

                {text.name !== "Solutions" && text.name !== "Blog" && (
                  <Link className={link} to={text.pageLink}>
                    <span>{text.name}</span>
                  </Link>
                )}
                {text.name === "Solutions" && (
                  <div
                    style={{ width: "100%", fontWeight: 600 }}
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    {
                      <SliderAccordion
                        expanded={expanded === ProductsSummary.id}
                        onAccordionChange={handleChange(ProductsSummary.id)}
                        onClickDetail={handleDetail}
                        accordionClasses={{
                          root: accordion,
                          expandIcon,
                          summary,
                        }}
                        renderComponentOverride={() => (
                          <ProductsBox SubProductLinks={[...Products]} />
                        )}
                        detail={ProductsSummary}
                      />
                    }
                  </div>
                )}
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </div>
  );
};
export default MobileMenu;
