import { makeStyles, useMediaQuery } from "@material-ui/core";
import React from "react";
import Caption from "../../components/caption";
import SliderCarousel from "../../components/carousel";
import { PartnerLogos } from "../../content/Home";
import _ from "lodash";
import MainContainer from "../../components/mainContainer";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    margin: `${theme.spacing(3)}px 0`,
  },
  arrowStyles: {
    color: "#00000081",
    position: "absolute",
    zIndex: 2,
    top: "32%",
    cursor: "pointer",
    backgroundColor: "transparent",
  },
  partnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-evenly",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "25%",
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  image: {
    width: "unset !important",
    height: 50,
    [theme.breakpoints.down("sm")]: {
      height: 35,
    },
  },

  partnerLogoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alignPartnerContainer: {
    display: "flex",
  },

  partnerLogo: {
    width: "100%",
  },
}));

const Partners = (props) => {
  const {
    partnerContainer,
    alignPartnerContainer,
    imageContainer,
    image,
    arrowStyles,
    contentContainer,
    partnerLogoContainer,
    partnerLogo,
  } = useStyles();
  const mobileMatcher = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <MainContainer>
      <div className={contentContainer}>
        <Caption>Our Integrations</Caption>
        <div className={partnerLogoContainer}>
          <div className={partnerLogo}>
            <SliderCarousel autoPlay arrowStyles={arrowStyles}>
              {_.chunk(PartnerLogos, mobileMatcher ? 2 : 5).map(
                (partnerWrapper, partnerWrapperIndex) => (
                  <div className={partnerContainer} key={partnerWrapperIndex}>
                    <div className={alignPartnerContainer}>
                      {partnerWrapper.map((partner) => (
                        <div className={imageContainer} key={partner.id}>
                          <img
                            className={image}
                            key={partner.id}
                            src={partner.source}
                            alt={`Partner ${partner.id}`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </SliderCarousel>
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default Partners;
