import { createMuiTheme } from "@material-ui/core";
import { font } from "./_variables.scss";

export const theme = createMuiTheme({
  fontFamily: font,
  typography: {
    fontFamily: font,
    semiBold: 600,
    button: {
      textTransform: "capitalize",
    },
  },
  palette: {
    primary: {
      main: "#F07663",
    },
    background: {
      default: "#FFFFFF",
    },
    text: {
      primary: "#333333",
    },
  },
});
