import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ProductTabs, ProductCustomTab } from "./productTab/productTab";
import TabContents from "./productTab/TabContents";
import TabPanel from "./productTab/TabPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "93%",
    [theme.breakpoints.between("xs", "sm")]: {
      width: "100%",
    },
    height: "auto",
    margin: "auto",
    // border: "1px solid #70707075",
    borderRadius: "40px 40px 0px 0",
    marginTop: 50,
  },
  tabs: {
    borderBottom: "1px solid #70707075",
  },
  tabRight: {
    backgroundColor: "#fafafa",
    fontWeight: "500",
    padding: "20px 10px",
  },
  productTabContainer: {
    [theme.breakpoints.between("xs", "sm")]: {
      border: "2px solid lightgray",
      borderRadius: "50px 50px 0 0",
    },
  },
  mobileDesign: {
    backgroundColor: "#fafafa",
    fontWeight: "500",
    padding: "20px 10px",
    [theme.breakpoints.between("xs", "sm")]: {
      border: "2px solid lightgray",
      borderBottom: "none",
      borderTop: "none",
    },
  },
}));

export default function FullWidthTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { tab, tabInfo } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <ProductTabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        className={classes.productTabContainer}
      >
        {tab.map((tab, index) => (
          <ProductCustomTab
            disableRipple
            label={tab.name}
            className={index === 1 ? classes.mobileDesign : classes.tabRight}
            key={index}
          />
        ))}
      </ProductTabs>
      <>
        {tabInfo &&
          tabInfo.map((product, index) => (
            <TabPanel value={value} index={index} key={product.id}>
              <TabContents productInfo={tabInfo[index]} />
            </TabPanel>
          ))}
      </>
    </div>
  );
}
