export const bannerContent = {
  caption: "Power up your sales team with a click",
  medium: "Field Sales Assist",
  button: "Get In Touch",
  source: require("../../assets/Products/fieldSalesAssist/undraw_predictive_analytics_kf9n (4).svg"),
};

export const platformContent = {
  title: "FieldSalesAssist keeps scheduling hassle-free",
  caption: "Assisting your field sales teams with optimized workflows",
};

export const productCard = [
  {
    id: 1,
    title: "Optimize resource scheduling",
    list: ["Mobilize resources that fit right", "Manage schedule integrity"],
  },
  {
    id: 2,
    title: "Identify variances",
    list: [
      "Evaluate performance and productivity",
      "Identify critical paths and mitigate delays",
    ],
  },
  {
    id: 3,
    title: "Collaborate",
    list: [
      "FieldSalesAssist streamlines sales teams across branches",
      "Easy approvals and processes help in cutting costs",
    ],
  },
];

export const productTabList = [
  { id: 1, name: "Effective staff allocation" },
  { id: 2, name: "Proactive field services" },
  { id: 3, name: "Ready intel on hand" },
];

export const productInfo = [
  {
    id: 1,
    source: require("../../assets/Products/fieldSalesAssist/career__monochromatic (1).svg"),
    content: {
      title: "Effective staff allocation",
      details:
        "With FieldSalesAssist, you can allocate your sales team more consistently, preventing ebbs and flows and increasing the bottom line.",
    },
  },
  {
    id: 2,
    source: require("../../assets/Products/fieldSalesAssist/online_payment_monochromatic.svg"),
    content: {
      title: "Proactive field services",
      details:
        "Analytics-driven insights help teams to take initiatives that are in sync with customer expectations.",
    },
  },
  {
    id: 3,
    source: require("../../assets/Products/fieldSalesAssist/creative_process_monochromatic.svg"),
    content: {
      title: "Ready intel on hand",
      details:
        "AI Engine helps in assigning work based on rules and enables your team to improve resolution rates.",
    },
  },
];

export const demoContents = {
  caption: "Let's empower the field sales team",
  button: "Book a Demo",
};

export default {
  bannerContent,
  platformContent,
  productCard,
  productTabList,
  productInfo,
};
