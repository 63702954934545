import { makeStyles, useMediaQuery } from "@material-ui/core";
import React from "react";
import Caption from "./caption";
import SliderCarousel from "./carousel";
import { BenifitedLogos } from "../content/Home";
import MainContainer from "./mainContainer";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  benifit: {
    // backgroundColor: "#F6F6F6",
    padding: theme.spacing(2),
    // margin: ` ${theme.spacing(3)}px 0`,
    margin: "50px 0 25px 0",
    minHeight: 147,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "25px",
      padding: 0,
      fontSize: "14px",
    },
  },
  benifitCaption: {
    fontWeight: theme.typography.semiBold,
  },
  benifitedLogoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      overflow: "hidden",
    },
  },
  logoList: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "repeat(7,1fr)",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      width: "100%",
      padding: 0,
    },
    width: "75%",
    listStyleType: "none",
  },
  benifitedLogo: {
    width: 95,
    height: 21,
    [theme.breakpoints.down("sm")]: {
      width: 123,
      height: 28,
    },
  },
  logoItem: {
    textAlign: "center",
  },
  arrowStyles: {
    color: "#00000081",
    position: "absolute",
    zIndex: 2,
    top: "30%",
    cursor: "pointer",
    backgroundColor: "transparent",
  },
  benifitedContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: theme.spacing(3),
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      width: "unset",
    },
  },

  image: {
    width: "unset !important",

    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },
  },

  partnerContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      padding: "0 20px",
    },
  },
  alignPartnerContainer: {
    display: "flex",
  },

  benefitedLogo: {
    width: "100%",

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  arrowIcon: {
    height: "23px",
  },
  // arrowStyles: {
  //   color: "#00000081",
  //   position: "absolute",
  //   zIndex: 2,
  //   top: "32%",
  //   cursor: "pointer",
  //   backgroundColor: "transparent",
  // },
}));

const CustomerBenifitHistory = (props) => {
  const {
    benifit,
    benifitedLogoContainer,
    benefitedLogo,
    partnerContainer,
    alignPartnerContainer,
    imageContainer,
    arrowStyles,
    image,
  } = useStyles();
  const mobileMatcher = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <div className={benifit}>
      <MainContainer>
        <Caption align="center">Trusted By Top Brands of India</Caption>
        <br />
        <div className={benifitedLogoContainer}>
          <div className={benefitedLogo}>
            <SliderCarousel autoPlay arrowStyles={arrowStyles}>
              {_.chunk(BenifitedLogos, mobileMatcher ? 2 : 5).map(
                (partnerWrapper, partnerIndex) => (
                  <div className={partnerContainer} key={partnerIndex}>
                    <div className={alignPartnerContainer}>
                      {partnerWrapper.map((partner) => (
                        <div className={imageContainer} key={partner.id}>
                          <img
                            key={partner.id}
                            src={partner.source}
                            alt={`Partner ${partner.id}`}
                            className={image}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </SliderCarousel>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default CustomerBenifitHistory;
