module.exports = {
  envName: "dev",
  dashboardUrl: "http://localhost:5335",
  ruleServerUrl: "http://localhost:3004",
  casaWebAppUrl: "http://localhost:3020",
  casaEcomUrl: "http://localhost:3000",
  casaBeatUrl: "https://beat-api.casaqa.ajira.tech",
  casaWebsiteUrl: "http://localhost:3000",
  restrictedFeature: []
};
