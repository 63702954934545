import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import { benefits } from "../../content/careers";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#f4f5f7",
    fontFamily: "'Nunito'",
    paddingBottom: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 32,
    margin: 16,
    fontWeight: 900,
    color: "#F07663",
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
  },
  container: {
    width: "80%",
    // marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  benefitGrid: {
    display: "flex",
    alignItems: "center",
    padding: 18,
    paddingLeft: 24,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 28,
    },
  },
  benefitContent: {
    width: "80%",
    marginLeft: 8,
    fontSize: 17,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: 16,
    },
  },
}));

const Benefits = (props) => {
  const { root, title, container, benefitGrid, benefitContent } = useStyles();
  return (
    <>
      <div className={root}>
        <div className={title}>Benefits</div>
        <div className={container}>
          <Grid container>
            {benefits &&
              benefits.map((benefit) => {
                return (
                  <Grid
                    item
                    sm={6}
                    xs={12}
                    lg={4}
                    className={benefitGrid}
                    key={benefit.id}
                  >
                    <img src={benefit.source} alt={benefit.content} />
                    <div className={benefitContent}>{benefit.content}</div>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Benefits;
