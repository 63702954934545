import { MuiThemeProvider } from "@material-ui/core";
import React from "react";
import Main from "./pages/Home";
import "./styles/styles.scss";
import { theme } from "./styles/theme";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AppContainer from "./components/appContainer";
import TestimonialMain from "./pages/Testimonial/Index";
import TeamMain from "./pages/Team/index";
import Products from "./pages/Products/index";
import { SignUp } from "./components/SignUp/SignUp";
import BlogsMain from "./pages/Blogs/Index";
import { isFeatureRestricted } from "./utils/feature-validate";
import { Features } from "./utils/constants";
import Careers from "./pages/careers/index";
import Roles from "./pages/careers/aboutRole/index";
import Home from "./pages/Home/Home";
import Steps from "./pages/Steps/Steps";

// import PrivacyPolicy from "./pages/PrivacyPolicy";

function App(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter forceRefresh={false}>
        <AppContainer>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/home" component={Main} />
            <Route exact path="/products/:name" component={Products} />
            <Route exact path="/testimonial" component={TestimonialMain} />
            <Route exact path="/team" component={TeamMain} />
            <Route exact path="/careers" component={Careers} />
            <Route exact path="/careers/:name" component={Roles} />
            <Route exact path="/steps" component={Steps} />

            {!isFeatureRestricted(Features.SELFSERVICE) && <Route exact path="/signup" component={SignUp} />}
            <Route exact path="/blogs" component={BlogsMain} />
            {/* <Route
							exact
							path="/privacyPolicy"
							component={PrivacyPolicy}
						/> */}
          </Switch>
        </AppContainer>
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

export default App;
