import React from "react";
import Helmet from "react-helmet";

const HeadTagDetails = () => {
  return (
    <>
      <Helmet>
        <title>Drive sales using our online digital catalog | FlipSell</title>
        <meta
          name="description"
          lang="en"
          content="FlipSell connects your customers to amazing content with shareable high-quality online digital catalog to showcase products across digital platforms"
        ></meta>
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Drive sales using our online digital catalog | FlipSell"
        />
        <meta
          property="og:description"
          content="FlipSell connects your customers to amazing content with shareable high-quality online digital catalog to showcase products across digital platforms"
        />
        <meta
          property="og:url"
          content="https://casaretail.ai/products/flipSell"
        />
        <meta name="twitter:site" content="@casaretail_ai" />
        <meta
          name="twitter: title"
          content="Drive sales using our online digital catalog | FlipSell"
        />
        <meta
          name="twitter:description"
          content="FlipSell connects your customers to amazing content with shareable high-quality online digital catalog to showcase products across digital platforms"
        />
      </Helmet>
    </>
  );
};

export default HeadTagDetails;
