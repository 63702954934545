import React from "react";
import Banner from "../SubProduct/Banner";
import AboutPlatform from "../SubProduct/AboutPlatform";
import ProductCards from "../SubProduct/ProductCards";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import ProductTab from "../SubProduct/ProductTab";
import DemoSection from "../SubProduct/DemoSection";
import * as content from "../../../content/Products/fieldSalesAssist";
import HeadTagDetails from "./HeadDetails";

const customTheme = (theme) =>
  createMuiTheme({
    ...theme,
    fontSize: 24,
  });

const main = () => {
  return (
    <>
      <HeadTagDetails />
      <MuiThemeProvider theme={customTheme}>
        <Banner content={content.bannerContent} />
        <AboutPlatform content={content.platformContent} />
        <ProductCards content={content.productCard} />
        <ProductTab
          tab={content.productTabList}
          tabInfo={content.productInfo}
        />
        <DemoSection content={content.demoContents} />
      </MuiThemeProvider>
    </>
  );
};

export default main;
