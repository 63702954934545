import React from "react";
import { Box } from "@material-ui/core";

export default function TabPanel(props) {
	const { children, value, index } = props;
	return (
		<div>
			{value === index && (
				<Box p={3} style={{ background: "#f4f4f46b" }}>
					<div>{children}</div>
				</Box>
			)}
		</div>
	);
}
