export const leaders = [
  {
    id: 1,
    source: require("../assets/Team/Image 61.png"),
    name: "Sat Vijayragavan",
    position: "Founder and CEO",
    description:
      "25+ years of experience in global software development and 15+ year in retail technology sector. Ex global head of retail at ThoughtWorks & Wipro.",
  },
  {
    id: 2,
    source: require("../assets/Team/Image 77.png"),
    name: "Sundararajan Govindarajan",
    position: "Co-Founder",
    description:
      "25+ years experience and 12+ years of experience in the retail tech industry. Has worked with most of the top 100 retailers across various categories.",
  },
  {
    id: 3,
    source: require("../assets/Team/Image 91.png"),
    name: "Ranjit Valerian",
    position: "Business Head",
    description:
      "A Business Leader with 20 years of rich experience who understands both the retail business practices and the technology solutions surrounding it which helps him to relate well with various stakeholders in different Organizations with ease.",
  },
  {
    id: 4,
    source: require("../assets/Team/Image 78.png"),
    name: "Raghavan",
    position: "Engineering Lead",
    description:
      "A software genius and the moral compass of CASA. Dog’s best friend. The first to get into office and last to leave. There is no CASA without him.",
  },
];

export const craftsmanList = [
  {
    id: 0,
    source: require("../assets/Team/team-sat.png"),
    position: "CEO, Founder",
    name: "Sat",
    Qone: "What are your ninja skills?",
    Aone: "Building a team that trusts and works for each other.",
    Qtwo: "One word that describes you?",
    Atwo: "Grit.",
    Qthree: "Motto in life?",
    Athree: "Think Different. Execute Fast. Everyone wins.",
    Qfour: "Whats your interesting hobby and why do you practise it?",
    Afour: "Watching daughter skate, speed thrills but does not kill.",
  },
  {
    id: 1,
    source: require("../assets/Team/team-srinidhi.png"),
    position: "GrowthHacker, Casa",
    name: "Srinidhi",
  },
  {
    id: 2,
    source: require("../assets/Team/raghavendra.png"),
    position: "Program Manager - Implementations",
    name: "Raghavendar Shyam ",
    Qone: "One word that describes you in life?",
    Aone: "Tactician",
    Qtwo: "Your motto in life?",
    Atwo: "Keep your eyes on the prize.",
    Qthree: "You are also called as?",
    Athree: "Ragha, Raghav, Gauti",
    Qfour: "Your favourite quote?",
    Afour: "Never let the fear of striking out keep you from playing the game. - Babe Ruth",
  },
  {
    id: 3,
    source: require("../assets/Team/team-saravanan.png"),
    position: "Operations Manager",
    name: "Saravanan",
  },
  {
    id: 4,
    source: require("../assets/Team/Image 88.png"),
    position: "Software Engineer",
    name: "Sinthiya",
  },
  {
    id: 5,
    source: require("../assets/Team/Ragul.jpg"),
    position: "Software Engineer",
    name: "Ragul",
    Qone: "Your serious message to the public.",
    Aone: "Give priority to humanity over money.",
    Qtwo: "How will you punish your greatest enemy?",
    Atwo: "Stay calm and chill out.",
    Qthree: "Motto in life?",
    Athree: "Fight till the last breath.",
    Qfour: "Your favourite quote",
    Afour: "நாளை என்றும் நம் கையில் இல்லை நாம் யாரும் தேவன் கைபொம்மைகளே",
  },
  {
    id: 6,
    source: require("../assets/Team/bala.jpeg"),
    position: "Software Engineer",
    name: "Bala",
  },
  {
    id: 7,
    source: require("../assets/Team/shriram.png"),
    position: "Software Engineer",
    name: "Shriram",
  },
  {
    id: 8,
    source: require("../assets/Team/sabareesan.jpg"),
    position: "Software Engineer",
    name: "Sabareesan",
    Qfour: "Your favourite quote",
    Afour: "Those Without Many Abilities Tend To Complain More",
  },
  {
    id: 9,
    source: require("../assets/Team/Anu.jpg"),
    position: "SDET",
    name: "Anugna",
  },
  {
    id: 10,
    source: require("../assets/Team/sp.jpg"),
    position: "SDET",
    name: "Sivapraba Sivakumar",
  },
  {
    id: 11,
    source: require("../assets/Team/Swetha Loganathan.jpg"),
    position: "HR",
    name: "Swetha Loganathan",
    Qfour: "Your favourite quote",
    Afour: "Live and Let Live",
  },
  {
    id: 12,
    source: require("../assets/Team/Charu.jpeg"),
    position: "Software Engineer",
    name: "Charumathy",
  },
  {
    id: 13,
    source: require("../assets/Team/Abhishek.jpg"),
    position: "Senior Software Engineer",
    name: "Abhishek",
  },
  {
    id: 14,
    source: require("../assets/Team/Rishvanth.jpeg"),
    position: "Junior Software Engineer",
    name: "Rishvanth",
  },
  {
    id: 15,
    source: require("../assets/Team/Dharshini.jpg"),
    position: "Junior Software Engineer",
    name: "Dharshini Palani",
  },
  {
    id: 16,
    source: require("../assets/Team/Maignanaganapathy.jpg"),
    position: "Software Engineer",
    name: "Mai Gnana Ganapathy",
  },
  {
    id: 17,
    source: require("../assets/Team/Sai.jpg"),
    position: "Senior Customer Success Associate",
    name: "Sai Lakshmi Ramji",
  },
  {
    id: 18,
    source: require("../assets/Team/Sivasankari.jpg"),
    position: "Junior Software Engineer",
    name: "Sivasankari",
  },
  {
    id: 19,
    source: require("../assets/Team/Ranjith.jpg"),
    position: "Marketing Associate",
    name: "Ranjith",
  },
  {
    id: 20,
    source: require("../assets/Team/Shilpesh.jpeg"),
    position: "Senior Customer Success Executive",
    name: "Shilpesh Raj",
  },
  {
    id: 21,
    source: require("../assets/Team/Bavi.jpg"),
    position: "Junior Software Engineer",
    name: "Bavithraraj",
  },
  {
    id: 22,
    source: require("../assets/Team/Nisar.jpg"),
    position: "Junior Software Engineer",
    name: "Nisar Ahamed",
  },
  {
    id: 23,
    source: require("../assets/Team/sam.jpg"),
    position: "Software Engineer",
    name: "Samuel Mameru Carr ",
  },
];

export const evangelists = [
  {
    id: 1,
    source: require("../assets/Team/Image 92.png"),
    position: "Zonal Head",
    name: "Rahul Pandey",
  },
  {
    id: 2,
    source: require("../assets/Team/sachin.jpeg"),
    position: "Sales and Marketing Lead",
    name: "Sachin",
  },
  {
    id: 3,
    source: require("../assets/Team/raghavendra.png"),
    position: "Program Manager - Implementations",
    name: "Raghavendar Shyam ",
  },
  // {
  //   id: 4,
  //   source: require("../assets/Team/IMG_20190803_083559.png"),
  //   position: "Senior Product Consultant",
  //   name: "Ajay Simhan",
  // },
];
