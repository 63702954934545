import React from "react";
import Helmet from "react-helmet";

const HeadTagDetails = () => {
  return (
    <>
      <Helmet>
        <title>Power Up Sales Team | FieldSalesAssist</title>
        <meta
          name="description"
          lang="en"
          content="Assisting your field sales teams with optimized workflows using FieldSalesAssists"
        ></meta>
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Power Up Sales Team | FieldSalesAssist"
        />
        <meta
          property="og:description"
          content="Assisting your field sales teams with optimized workflows using FieldSalesAssists"
        />
        <meta
          property="og:url"
          content="https://casaretail.ai/products/fieldSalesAssist"
        />
        <meta name="twitter:site" content="@casaretail_ai" />
        <meta
          name="twitter: title"
          content="Power Up Sales Team | FieldSalesAssist"
        />
        <meta
          name="twitter:description"
          content="Assisting your field sales teams with optimized workflows using FieldSalesAssists"
        />
      </Helmet>
    </>
  );
};

export default HeadTagDetails;
