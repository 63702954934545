import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListOfLinks from "./listOfLinks";

export const Accordion = withStyles((theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    backgroundColor: "transparent",
    color: "inherit",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
}))(MuiAccordion);

export const AccordionSummary = withStyles((theme) => ({
  root: {
    borderBottom: "3px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    fontWeight: theme.typography.semiBold,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

export default function SliderAccordion(props) {
  const {
    detail,
    accordionClasses,
    onClickDetail,
    onAccordionChange,
    expanded,
    renderComponentOverride,
  } = props;

  const RenderComponent = renderComponentOverride;

  return (
    <Accordion
      expanded={expanded}
      square
      onChange={onAccordionChange}
      className={accordionClasses.root}
    >
      <AccordionSummary
        className={accordionClasses.summary}
        expandIcon={<ExpandMoreIcon className={accordionClasses.expandIcon} />}
        aria-controls={`panel-content-${detail.id}`}
        id={`panel-header-${detail.id}`}
      >
        <Typography style={{ fontWeight: 600 }}>{detail.summary}</Typography>
      </AccordionSummary>
      <MuiAccordionDetails onClick={onClickDetail} key={detail.id}>
        {RenderComponent !== null ? (
          <RenderComponent />
        ) : (
          <ListOfLinks details={detail.details} />
        )}
      </MuiAccordionDetails>
    </Accordion>
  );
}
SliderAccordion.defaultProps = {
  renderComponentOverride: null,
};
