export const historicBillData = [
    "Site Short code",
    "ADMSITE_CODE",
    "CODE",
    "CUID",
    "BILLNO",
    "BILLDATE",
    "TERMINAL",
    "CREATEDON",
    "CREATEBY",
    "GUID",
    "Customer First name",
    "Customer Middle name",
    "Customer Last name",
    "ADDRESS1",
    "ADDRESS2",
    "ADDRESS3",
    "CITY",
    "STATE",
    "PIN",
    "COUNTRY",
    "PHONE1",
    "PHONE2",
    "MOBILE",
    "EMAIL",
    "Date of Birth",
    "ICODE",
    "QTY",
    "Maximum Retail Price",
    "Retail Sale Price",
    "Extended Sale Price",
    "Qty * MRP",
    "Qty * ESP",
    "Promo Amount",
    "Item Level Discount",
    "Item Gross Amount",
    "Memo Discount Amount",
    "Memo Gross Amount",
    "LP Discount Amount",
    "Net Amount",
    "Total Discount Amount",
    "TAXAMT",
    "TAXABLEAMT",
    "HSN / SAC code of Item",
    "IGST Rate",
    "IGST Amount",
    "CGST Rate",
    "CGST Amount",
    "SGST Rate",
    "SGST Amount",
    "CESS Rate",
    "CESS Amount",
    "Org Bill Generated from Site",
    "Original Bill no",
    "Original Bill Date",
    "Original Bill Code",
    "PROMONO",
    "PROMONAME",
    "M_PROMONO",
    "M_PROMONAME",
  ];
  
  export const itemMasterData = [
    "Master SKU",
    "SKU",
    "Name",
    "Category",
    "color_test"
  ];
  
  export const storeMasterData = [
    "store",
    "storeNameAlias",
    "mobile",
    "addressLineOne",
    "addressLineTwo",
    "location",
    "startDate",
    "latitude",
    "longitude",
    "city",
    "state",
    "pincode",
    "gstin",
    "isActive",
    "blockLoyaltyEarn",
    "storeManagerEmail",
    "digitalStoreName",
    "landline",
    "storeTags_businessUnit",
    "storeTags_City"
  ];
  
  export const userListData = [
    "email",
    "username",
    "mobile",
    "role",
    "stores",
    "password"
  ]