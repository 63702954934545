import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  linkTabs: {
    display: "flex",
    color: "inherit",
    textDecoration: "none",
    padding: theme.spacing(1.5),
    paddingLeft: 0,
    minHeight: 84,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  headerStyle: {
    fontWeight: theme.typography.semiBold,
    fontSize: 14,
    lineHeight: 1.5,
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  discriptionStyle: {
    fontSize: 11,
  },
  linkContainer: {
    padding: theme.spacing(1),
  },
  image: {
    width: "75% !important",
    [theme.breakpoints.down("sm")]: {
      width: ` 45px !important`,
    },
  },
  subProductContainer: {
    padding: "12px 4px 4px 4px",
    borderRadius: "5px",
    "&:hover": {
      // background:
      //   "transparent linear-gradient(0deg, #FFEBEB 0%, #FFFFFF 100%) 0% 0% no-repeat",
      background: "rgba(240, 118, 99, 0.08)",
      boxShadow: "0px 4px 4px rgba(191, 191, 191, 0.25)",
    },
    [theme.breakpoints.down("sm")]: {
      padding: `0`,
      display: "flex",
      alignItems: "center",
    },
  },
  headerContainerStyle: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "15px",
    },
  },
}));

const SubProductLink = (props) => {
  const { imgSrc, discription, header, path, disableHover } = props;
  const {
    linkTabs,
    image,
    headerStyle,
    discriptionStyle,
    headerContainerStyle,
    subProductContainer,
  } = useStyles();

  return (
    <Link to={path} className={linkTabs}>
      <Grid container className={!disableHover ? subProductContainer : ""}>
        <Grid item xs={3}>
          <img className={image} src={imgSrc} alt="Subproduct" />
        </Grid>
        <Grid item xs={9} className={headerContainerStyle}>
          <Typography className={headerStyle}>{header}</Typography>
          <Typography className={discriptionStyle}>{discription}</Typography>
        </Grid>
      </Grid>
    </Link>
  );
};

export default SubProductLink;
