import React from "react";
import { makeStyles } from "@material-ui/core";
// import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  linkStyle: {
    width: 150,
    fontSize: 14,
  },

  navLink: {
    padding: 10,
    cursor: "pointer",

    "&:hover": {
      background:
        "transparent linear-gradient(0deg, #FFEBEB 0%, #FFFFFF 100%) 0% 0% no-repeat",
    },
  },

  link: {
    textDecoration: "none",
    color: "inherit",
  },
}));

function Resources() {
  const { linkStyle, navLink, link } = useStyles();
  return (
    <div className={linkStyle}>
      <a target="blank" href="https://blogs.casaretail.ai/" className={link}>
        <div className={navLink}>Blogs</div>
      </a>

      {/* <Link to="/testimonial" className={link}>
        <div className={navLink}>Case Studies</div>
      </Link> */}
    </div>
  );
}

export default Resources;
