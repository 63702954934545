import React from "react";
import Helmet from "react-helmet";

const HeadTagDetails = () => {
  return (
    <>
      <Helmet>
        <title>Best Offer Management Tool | Offerio</title>
        <meta
          name="description"
          lang="en"
          content="Launch and manage offers automatically, intelligently & powers up sales & persona-linked offer management with offerio"
        ></meta>
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Best Offer Management Tool | Offerio"
        />
        <meta
          property="og:description"
          content="Launch and manage offers automatically, intelligently & powers up sales & persona-linked offer management with offerio"
        />
        <meta
          property="og:url"
          content="https://casaretail.ai/products/offerio"
        />
        <meta name="twitter:site" content="@casaretail_ai" />
        <meta
          name="twitter: title"
          content="Best Offer Management Tool | offerio"
        />
        <meta
          name="twitter:description"
          content="Launch and manage offers automatically, intelligently & powers up sales & persona-linked offer management with offerio"
        />
      </Helmet>
    </>
  );
};

export default HeadTagDetails;
