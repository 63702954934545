import React from "react";
import {
  Paper,
  Grid,
  makeStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import StarsIcon from "@material-ui/icons/Stars";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    width: "100%",
    margin: "0px",
    display: "flex",
    justifyContent: "center",
    marginTop: "-65px",
  },
  paper: {
    margin: "0px 20px",
    [theme.breakpoints.between("sm", "md")]: {
      margin: 0,
    },
    width: "280px",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    borderRadius: 15,
    height: "auto",
  },
  cardPosition: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    marginBottom: 2,
    paddingTop: 12,
  },
  listItem: {
    alignItems: "flex-start",
    padding: "0px 20px 8px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "8px 20px",
    },
  },
  listIcon: {
    color: "#D64539",
  },
  listContent: {
    marginTop: "0px",
    marginBottom: 6,
    "& > span": {
      fontFamily: " 'Nunito', sans-serif",
      fontSize: "17px",
    },
  },
}));

const ProductCards = (props) => {
  const {
    cardWrapper,
    paper,
    cardPosition,
    listItem,
    listIcon,
    title,
    listContent,
  } = useStyles();
  const { content } = props;

  return (
    <>
      <Grid container spacing={4} className={cardWrapper}>
        {content.map((product, index) => (
          <Grid item sm={4} xs={12} className={cardPosition} key={product.id}>
            <Paper elevation={4} className={paper}>
              <h3 className={title}>{product.title}</h3>
              <List>
                {product.list.map((subProducts, index) => (
                  <ListItem className={listItem} key={index}>
                    <ListItemIcon style={{ minWidth: 30 }}>
                      <StarsIcon className={listIcon} />
                    </ListItemIcon>
                    <ListItemText
                      primary={subProducts}
                      className={listContent}
                    />
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ProductCards;
