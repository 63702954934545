export const bannerContent = {
  caption: "Run smart campaigns like a pro",
  medium: "Journify",
  content: `Automate and personalize your sales campaign using insights`,
  button: "Get In Touch",
  source: require("../../assets/Products/journify/new_campaign_monochromatic.svg"),
};

export const platformContent = {
  title: "journify cuts the hype in running focused campaigns",
  caption:
    "Boosting sales does not have to be complicated with numerous variables",
};

export const productCard = [
  {
    id: 1,
    title: "Intelligent customer discovery",
    list: ["Relevant connections", "Predictive insights in real time"],
  },
  {
    id: 2,
    title: "Orchestrated campaigns",
    list: ["Improved conversions", "Reduced cost"],
  },
  {
    id: 3,
    title: "Automated personalization",
    list: ["Content personalization", "AI based channel selection"],
  },
];

export const productTabList = [
  { id: 1, name: "AI-driven targeting" },
  { id: 2, name: "Maximize your ROI" },
  { id: 3, name: "Execute campaign & relax" },
];

export const productInfo = [
  {
    id: 1,
    source: require("../../assets/Products/journify/undraw_Artificial_intelligence_re_enpp.svg"),
    content: {
      title: "AI-driven targeting",
      details:
        "Journify uses its AI-powered insights to build user-centric campaigns",
    },
  },
  {
    id: 2,
    source: require("../../assets/Products/journify/bank_note_monochromatic.svg"),
    content: {
      title: "Maximize your ROI",
      details:
        "Identifying the campaigns that work and investing in them can make all the difference. Our AI Engine shows you the way to do it",
    },
  },
  {
    id: 3,
    source: require("../../assets/Products/journify/undraw_buffer_wq43.svg"),
    content: {
      title: "Execute campaign & relax",
      details:
        "Journify AI engine helps you in connecting users to the content that they require, in real-time, and in the channel of their choice",
    },
  },
];

export const demoContents = {
  caption: "Come, let's personalize customer engagements",
  button: "Book a Demo",
};

export default {
  bannerContent,
  platformContent,
  productCard,
  productTabList,
  productInfo,
};
