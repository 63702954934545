import { Container, makeStyles } from "@material-ui/core";
import React from "react";
const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  pageItem: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: 10,
      fontWeight: 800,
    },
  },
  containerStyle: {
    padding: 0,
  },
}));
const MainContainer = (props) => {
  const { pageContainer, pageItem, containerStyle } = useStyles();
  return (
    <Container className={containerStyle}>
      <div className={pageContainer}>
        <div className={pageItem}>{props.children}</div>
      </div>
    </Container>
  );
};

export default MainContainer;
