import React from "react";
import Helmet from "react-helmet";

const HeadTagDetails = () => {
  return (
    <>
      <Helmet>
        <title>Digital Loyalty Management System | Retensee</title>
        <meta
          name="description"
          lang="en"
          content="RetenSee enables you to create completely digital personalized and scalable loyalty solution for brands to get closer to customer"
        ></meta>
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Digital Loyalty Management System | Retensee"
        />
        <meta
          property="og:description"
          content="RetenSee enables you to create completely digital personalized and scalable loyalty solution for brands to get closer to customer"
        />
        <meta
          property="og:url"
          content="https://casaretail.ai/products/retenSee"
        />
        <meta name="twitter:site" content="@casaretail_ai" />
        <meta
          name="twitter: title"
          content="Digital Loyalty Management System | Retensee"
        />
        <meta
          name="twitter:description"
          content="RetenSee enables you to create completely digital personalized and scalable loyalty solution for brands to get closer to customer"
        />
      </Helmet>
    </>
  );
};

export default HeadTagDetails;
