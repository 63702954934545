import _ from "lodash";
export const STATE = {
  0: "/home",
  2: "/team",
  3: "/careers",
  4: "/testimonial",
  5: "/blogs",
};

export const getKey = (value) => {
  return parseInt(
    _.findKey(STATE, function(o) {
      return String(o) === value;
    })
  );
};
