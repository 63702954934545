import React from "react";
import Helmet from "react-helmet";

const HeadTagDetails = () => {
  return (
    <>
      <Helmet>
        <title>Customer Data Management | Ubicuity</title>
        <meta
          name="description"
          lang="en"
          content="Treat each customer personally at scale with authentic AI-based ubicuity customer data management system"
        ></meta>
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Customer Data Management | Ubicuity"
        />
        <meta
          property="og:description"
          content="Treat each customer personally at scale with authentic AI-based ubicuity customer data management system"
        />
        <meta
          property="og:url"
          content="https://casaretail.ai/products/ubicuity"
        />
        <meta name="twitter:site" content="@casaretail_ai" />
        <meta
          name="twitter: title"
          content="Customer Data Management | Ubicuity"
        />
        <meta
          name="twitter:description"
          content="Treat each customer personally at scale with authentic AI-based ubicuity customer data management system"
        />
      </Helmet>
    </>
  );
};

export default HeadTagDetails;
