import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";
// import HeroButton from "../../../components/heroBtn";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 900,
    backgroundColor: "#f4f5f7",
  },
  rootAlign: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    padding: "28px 0",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      width: "90%",
      padding: "10px 0",
    },
  },
  title: {
    marginLeft: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  gridContent: {
    fontSize: 17,
    padding: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      padding: 10,
      textAlign: "left",
    },
  },
  btn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 24,
  },
  linkStyleContent: {
    color: "white",
    background: "#F07663",
    textDecoration: "none !important",
    padding: 10,
    borderRadius: 10,
  },
}));

const Skills = (props) => {
  const {
    root,
    rootAlign,
    title,
    gridContent,
    btn,
    linkStyleContent,
  } = useStyles();
  const role = props.role;

  return (
    <div className={root}>
      <div className={rootAlign}>
        <h2 className={title}>{role.experience}</h2>
        <Grid container>
          {role.skills &&
            role.skills.map((look) => {
              return (
                <Grid
                  item
                  sm={6}
                  xs={12}
                  lg={6}
                  //   className={benefitGrid}
                  key={look.id}
                >
                  <Typography className={gridContent}>
                    {look.content}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
        <div className={btn}>
          <a href="mailto:recruit@casaretail.ai" className={linkStyleContent}>
            Apply Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Skills;
