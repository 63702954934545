import React from "react";
import Helmet from "react-helmet";

const HeadTagDetails = () => {
  return (
    <>
      <Helmet>
        <title>Retail Customer Intelligence Tool | PWA</title>
        <meta
          name="description"
          lang="en"
          content="PWA powers your strategic customer engagement while providing tactical opportunities for immediate benefit"
        ></meta>
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Retail Customer Intelligence Tool | PWA"
        />
        <meta
          property="og:description"
          content="PWA powers your strategic customer engagement while providing tactical opportunities for immediate benefit"
        />
        <meta property="og:url" content="https://casaretail.ai/products/pwa" />
        <meta name="twitter:site" content="@casaretail_ai" />
        <meta
          name="twitter: title"
          content="Retail Customer Intelligence Tool | PWA"
        />
        <meta
          name="twitter:description"
          content="PWA powers your strategic customer engagement while providing tactical opportunities for immediate benefit"
        />
      </Helmet>
    </>
  );
};

export default HeadTagDetails;
