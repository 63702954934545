import { Grid, makeStyles, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 900,
  },
  rootAlign: {
    width: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "28px 0",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      fontSize: 16,
      padding: "13px 0",
    },
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  gridContent: {
    fontSize: 17,
    padding: 16,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
      padding: 12,
      textAlign: "left",
    },
  },
}));

const LookFor = (props) => {
  const { root, rootAlign, title, gridContent } = useStyles();
  const role = props.role;

  return (
    <div className={root}>
      <div className={rootAlign}>
        <h2 className={title}>What We Look For</h2>
        <Grid container>
          {role.lookFor &&
            role.lookFor.map((look) => {
              return (
                <Grid item sm={6} xs={12} lg={6} key={look.id}>
                  <Typography className={gridContent}>
                    {look.content}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </div>
  );
};

export default LookFor;
