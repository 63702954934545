import React from "react";
import CustomerBenifitHistory from "../../components/CustomBenifitHistory";
import Banner from "./Banner";
import CustomerFeedback from "./CustomerFeedback";
import CustomerTestimonial from "./CustomerTestimonial";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

const TestimonialMain = () => {
	return (
		<div>
			<Navbar/>
			<Banner />
			<CustomerBenifitHistory />
			<CustomerFeedback />
			<CustomerTestimonial />
			<Footer/>
		</div>
	);
};

export default TestimonialMain;
