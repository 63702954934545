import React from "react";
import { Link } from "react-router-dom";

import BrandLogo from "./BrandLogo";

const Brand = ({ logoWidth = 133, textColor }) => {
  return (
    <Link to="/home">
      <BrandLogo width={logoWidth} textColor={textColor} />
    </Link>
  );
};
export default Brand;
