import React from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Pagecaption from "./Pagecaption";
import Culture from "./Culture";
import Benefits from "./Benefits";
import Openings from "./Openings";

const Careers = (props) => {
  return (
    <>
      <Navbar />
      <Pagecaption />
      <Culture />
      <Benefits />
      <Openings />
      <Footer />
    </>
  );
};

export default Careers;
