export const Banner = {
  // Join a team that is revolutionizing customer experience and personalization

  title: "Join us to revolutionize ",
  subTitle: `The way we shop`,
  about:
    "We are reimagining omni channel shopping for the 21st century. If you are passionate about personalizing shopping experiences join us in making a difference ",
};

export const culture = {
  title: "The Casa Culture",
  about:
    "A team’s culture is a manifestation of the numerous interactions that go on between individuals. If there’s no trust, friendship and support between individuals, it won’t be reflected in the culture, and these develop over time through shared experiences.",
  source: require("../assets/careers/ajiraMembers.mp4"),
};

export const SubProducts = [
  {
    id: 1,
    title: "Ownership",
    source: require("../assets/careers/user-check.svg"),
    content:
      "No managers or analysts in our delivery model and hence everyone gets to interact with customers. Casa's culture fosters individual and team ownership where employees feel empowered to act.",
  },
  {
    id: 2,
    title: "Transparency",
    source: require("../assets/careers/eye.svg"),
    content:
      "Share information (good and bad) respectfully and without consequence. Actively seek out information and understanding from others.",
  },
  {
    id: 3,
    title: "Passion",
    source: require("../assets/careers/navigation.svg"),
    content:
      "A group of passionate people who keeps updating and actively searches for ways to upskill.",
  },
  {
    id: 4,
    title: "Empathy",
    source: require("../assets/careers/navigation.svg"),
    content: "Treat people with respect and show empathy towards each other",
  },
  {
    id: 5,
    title: "Continuous Learning",
    source: require("../assets/careers/book-open.svg"),
    content:
      "Environment where learning is available beyond projects (Spartans of software engineering)",
  },
  {
    id: 6,
    title: "Flat Structure",
    source: require("../assets/careers/navigation.svg"),
    content: "Best idea wins irrespective of person/role",
  },
  {
    id: 7,
    title: "Collaborative",
    source: require("../assets/careers/trello.svg"),
    content:
      "Emphasis on collaboration. Dev huddles to discuss best approach / tech that is not limited to that project",
  },
  {
    id: 8,
    title: "Fun and work coexist",
    source: require("../assets/careers/smile.svg"),
    content:
      "Playing ping-pong or x-box is same as writing user stories or code pairing, and we do all of it",
  },
];

export const benefits = [
  {
    id: 1,
    content: "Learning and Development budget",
    source: require("../assets/careers/benefits/book.svg"),
  },
  {
    id: 2,
    content: "Direct and daily interaction with customers",
    source: require("../assets/careers/benefits/users.svg"),
  },
  {
    id: 3,
    content: "Open source day - to give back to the community",
    source: require("../assets/careers/benefits/github.svg"),
  },
  {
    id: 4,
    content: "Competitive pay",
    source: require("../assets/careers/benefits/dollar-sign.svg"),
  },
  {
    id: 5,
    content: "Meals and Snacks",
    source: require("../assets/careers/benefits/coffee.svg"),
  },
  {
    id: 6,
    content: "Medical cover",
    source: require("../assets/careers/benefits/feather.svg"),
  },
  {
    id: 7,
    content: "Flexible working hours",
    source: require("../assets/careers/benefits/clock.svg"),
  },
  {
    id: 8,
    content: "Work from home options",
    source: require("../assets/careers/benefits/home.svg"),
  },
  {
    id: 9,
    content: "High end dev machines",
    source: require("../assets/careers/benefits/monitor.svg"),
  },
  {
    id: 10,
    content: "Quarterly team outing",
    source: require("../assets/careers/benefits/umbrella.svg"),
  },
  {
    id: 11,
    content: "Annual offsite",
    source: require("../assets/careers/benefits/globe.svg"),
  },
  {
    id: 12,
    content: "Health and wellness",
    source: require("../assets/careers/benefits/sun.svg"),
  },
  // {
  //   id: 13,
  //   content: "Work with top minds",
  //   source: require("../assets/careers/benefits/code.svg"),
  // },
];

export const openings = [
  {
    id: 1,
    title: "Engineering",
    location: "CHENNAI, INDIA - FULL TIME",
    jobs: [
      // {
      //   id: 1,
      //   role: "Customer Success Expert",
      //   path: "careers/customerSuccessExpert",
      // },
      // {
      //   id: 2,
      //   role: "Senior Software Engineer in Test",
      //   path: "careers/seniorSoftwareEngineerTest",
      // },
      {
        id: 1,
        role: "Senior Software Engineer",
        path: "careers/seniorSoftwareEngineer",
      },
      {
        id: 2,
        role: "Lead Software Engineer",
        path: "careers/leadSoftwareEngineer",
      },
    ],
  },
];

export const roles = [
  {
    id: 1,
    name: "customerSuccessExpert",
    position: "Customer Success Expert",
    location: "Chennai, India - Full Time",
    logo: require("../assets/Home/casa-retailai-dark.png"),
    list: [
      {
        id: 1,
        content:
          "Are you good at drawing out various flows from higher level concepts?",
      },
      {
        id: 2,
        content:
          "Do you feel satisfaction from bringing product ideas to life through your analysis?",
      },
      {
        id: 3,
        content:
          "Do you get high from thinking through approaches to simplify processes?",
      },
      {
        id: 4,
        content:
          "Do you have excellent writing , listening and presentation skills?",
      },
    ],
    aboutCompany:
      "We are creating one of the strongest tech companies and are working extensively on cutting edge technologies.",
    lookFor: [
      {
        id: 1,
        content:
          "Collaborate with project sponsors to determine project scope and vision",
      },
      {
        id: 2,
        content:
          "Must be logically strong in the analysis, design and building of critical and complex business applications",
      },
      {
        id: 3,
        content:
          "Work closely with stakeholders, designers on mock ups, user journey mapping",
      },
      {
        id: 4,
        content: "Create story mapping and story narratives",
      },
      {
        id: 5,
        content:
          "Conduct story kickoff - discussion with devs and the tech team",
      },
      {
        id: 6,
        content: "Manage the story wall - Both physical and digital",
      },
      {
        id: 7,
        content:
          "Facilitate workshops to elicit requirements and generate ideas and content for solving difficult problems",
      },
    ],
    experience: "As a Business Analyst with 3+ years of experience…..",
    skills: [
      {
        id: 1,
        content:
          " Experience working successfully with teams to deliver Software as a service and product",
      },
      {
        id: 2,
        content:
          " Strong at prioritising competing demands and obtaining buy-in from stakeholders",
      },
      {
        id: 3,
        content: " Ability to manage and resolve conflict with stakeholders",
      },
      {
        id: 4,
        content:
          " Should understand and explain issues from both a technical and a business functional point of view",
      },
      {
        id: 5,
        content:
          " Should be open to jump into projects and situations and make sense of ambiguous details in multiple domains",
      },
      {
        id: 6,
        content:
          "Clear insight of traditional development approaches, and the ability to compare and contrast benefits of each",
      },
      {
        id: 7,
        content:
          "Hands on experience in mentoring and executing Agile projects in a BA capacity",
      },
      {
        id: 8,
        content:
          "A clear pathway or vision for quality software delivery- and the know-how to get from sketch to code",
      },
    ],
  },
  {
    id: 2,
    name: "seniorSoftwareEngineerTest",
    position: "Senior Software Engineer in Test",
    location: "Chennai, India - Full Time",
    logo: require("../assets/Home/casa-retailai-dark.png"),
    list: [
      {
        id: 1,
        content: "Are you passionate about quality and testing?",
      },
      {
        id: 2,
        content: "Do you have a critical eye to recognize risk and errors?",
      },
      {
        id: 3,
        content:
          "Are you feeling that your learning is not as high as it can be?",
      },
      {
        id: 4,
        content: "Do you want to have great client consulting experience?",
      },
    ],
    // aboutCompany:
    // "We are creating one of the strongest tech companies and are working extensively on cutting edge technologies.",
    lookFor: [
      {
        id: 1,
        content: `Bring in a "can-do" attitude.`,
      },
      {
        id: 2,
        content:
          "Work together in a fast-paced environment to solve customer challenges.",
      },
      {
        id: 3,
        content:
          "Work with customers to bring an end to end perspective of the problem.",
      },
      {
        id: 4,
        content:
          "Passion towards technology and software engineering - blogging, open source, conference talks.",
      },
      {
        id: 5,
        content:
          "Participate in a highly fluid environment applying agile software development principles.",
      },
      {
        id: 6,
        content:
          "Carry out unit tests and other quality control mechanisms to inform and validate the designs and code.",
      },
      {
        id: 7,
        content:
          "Ability to collaborate with various stakeholders and take complete ownership.",
      },
      {
        id: 8,
        content:
          "Participate actively in building one of the strongest technology organizations.",
      },
    ],
    experience:
      "As a Senior Software Engineer in Test with 3-5 year of experience...",
    skills: [
      {
        id: 1,
        content:
          " Ability to work with product owners/business analysts to understand needs that are solved by the product/application",
      },
      {
        id: 2,
        content:
          " Ability to have a bird’s eye view of the product being built to ensure that appropriate dependencies are tested out well",
      },
      {
        id: 3,
        content: "Design good test cases, write and execute it",
      },
      {
        id: 4,
        content: "Perform non-functional testing with minimal support",
      },
      {
        id: 5,
        content:
          "Possess strong knowledge of test tools and techniques. Should have been involved as a primary manual/automation tester in several web/mobile applications",
      },
      {
        id: 6,
        content:
          "Responsible for defect reporting which includes the priority of defect logged and also manage the final quality on the production release",
      },
      {
        id: 7,
        content:
          "Demonstrate the ability to handle technical communication and understanding the customers' systems",
      },
      {
        id: 8,
        content:
          "Should be a quick learner to pick up newer testing frameworks since our projects span a variety of technologies",
      },
    ],
  },

  {
    id: 3,
    name: "seniorSoftwareEngineer",
    position: "Senior Software Engineer",
    location: "Chennai, India - Full Time",
    logo: require("../assets/Home/casa-retailai-dark.png"),
    list: [
      {
        id: 1,
        content:
          "Are you interested to work at a place where you are given the opportunity to grow and level up your skills?",
      },
      {
        id: 2,
        content:
          "Are you someone who has what it takes to energetically learn new concepts in technology?",
      },
      {
        id: 3,
        content:
          "Do you like working with an equally-energetic team, like-minded and passionate programmers?",
      },
      {
        id: 4,
        content: "Do you like to create impactful and scalable products?",
      },
    ],
    aboutCompany:
      "We are creating one of the strongest tech companies and are working extensively on cutting edge technologies.",
    lookFor: [
      {
        id: 1,
        content: `Bring in a "can-do" attitude.`,
      },
      {
        id: 2,
        content:
          "Passion towards technology and software engineering - blogging, open source, pet projects, conference talks",
      },
      {
        id: 3,
        content:
          "Participate in a highly fluid environment applying agile software development principles",
      },
      {
        id: 4,
        content:
          "Carry out unit tests and other quality control mechanisms to inform and validate the designs and code",
      },
      {
        id: 5,
        content:
          "Work with customers to bring an end to end perspective of the problem",
      },
      {
        id: 6,
        content:
          "Ability to ask the right questions and communicate your ideas with clarity",
      },
      {
        id: 7,
        content:
          "Ability to collaborate with various stakeholders and take complete ownership",
      },
      {
        id: 8,
        content:
          "Participate actively in building one of the strongest technology organizations",
      },
    ],
    experience: "As a Senior Software Engineer with 4+ years of experience…..",
    skills: [
      {
        id: 1,
        content:
          "Quickly adapt to our startup environment which is both demanding and fast-paced (but is also a lot of fun)",
      },
      {
        id: 2,
        content:
          "Eager in learning new concepts, technologies and be productive in short time",
      },
      {
        id: 3,
        content: "Convert a raw idea into usable real-life software products",
      },
      {
        id: 4,
        content:
          "Good understanding and working knowledge of technologies worked",
      },
      {
        id: 5,
        content: "Demonstrate good problem solving skills ",
      },
      {
        id: 6,
        content: "Write modular, readable and maintainable code",
      },
      {
        id: 7,
        content: "Own and be able to manage a small to medium sized project",
      },
      {
        id: 8,
        content:
          "Demonstrate ability to guide and coach a new team member or fresher",
      },
    ],
  },
  {
    id: 4,
    name: "leadSoftwareEngineer",
    position: "Lead Software Engineer",
    location: "Chennai, India - Full Time",
    logo: require("../assets/Home/casa-retailai-dark.png"),
    list: [
      {
        id: 1,
        content: "Are you bored with creating CRUD based systems?",
      },
      {
        id: 2,
        content:
          "Are you feeling that your learning is not as high as it can be?",
      },
      {
        id: 3,
        content:
          "Would you rather spend your time creating impact-full solutions that have complex engineering needs?",
      },
      {
        id: 4,
        content:
          "Want to wear your code and solution on your sleeve and not just aim for delivering a solution irrespective of the muck inside?",
      },
    ],
    aboutCompany:
      "We are creating one of the strongest tech companies and are working extensively on cutting edge technologies.",
    lookFor: [
      {
        id: 1,
        content: `Bring in a "can-do" attitude.`,
      },
      {
        id: 2,
        content:
          "Work together in a fast-paced environment to solve customer challenges.",
      },
      {
        id: 3,
        content:
          "Work with customers to bring an end to end perspective of the problem.",
      },
      {
        id: 4,
        content:
          "Passion towards technology and software engineering - blogging, open source, conference talks.",
      },
      {
        id: 5,
        content:
          "Participate in a highly fluid environment applying agile software development principles.",
      },
      {
        id: 6,
        content:
          "Carry out unit tests and other quality control mechanisms to inform and validate the designs and code.",
      },
      {
        id: 7,
        content:
          "Ability to collaborate with various stakeholders and take complete ownership.",
      },
      {
        id: 8,
        content:
          "Participate actively in building one of the strongest technology organizations.",
      },
    ],
    experience: "As a Lead Software Engineer with 7+ years of experience...",
    skills: [
      {
        id: 1,
        content:
          "Influence technical decision making and high level design decisions - choice of frameworks and tech approach",
      },
      {
        id: 2,
        content:
          "Know what to do in an inception(specifically on sound understanding of agile, ability to influence no trade off on practices etc)",
      },
      {
        id: 3,
        content:
          "Ability to make appropriate technology choices after evaluating multiple options and considering trade offs on small to medium projects",
      },
      {
        id: 4,
        content:
          "Demonstrate the ability to understand different approaches for application, integration and influence decisions by making appropriate trade offs",
      },
      {
        id: 5,
        content:
          "Specialise on non functional aspects (security, scalabitlity, performance, integration)",
      },
      {
        id: 6,
        content: "Understands configuration management and deployment issues.",
      },
      {
        id: 7,
        content:
          "Insights into performance and scalability issues on complex applications",
      },
      {
        id: 8,
        content:
          "Very strong design skills and understanding of software architecture",
      },
      {
        id: 9,
        content: "Demonstrate effective leadership and mentoring capabilities",
      },
    ],
  },
];
