import { makeStyles, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import MainContainer from "../../components/mainContainer";
import { Feedback } from "../../content/Home";
import Caption from "../../components/caption";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    position: "relative",
    display: "flex",
    minHeight: 550,
    [theme.breakpoints.down("sm")]: {
      padding: `25px ${theme.spacing(1)}px`,
      alignItems: "baseline",
      // minHeight: 435,
      minHeight: 500,
    },
  },

  feedbackCaption: {
    backgroundColor: "#FFEBEB",
    width: 790,
    minHeight: 250,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    position: "absolute",
    transform: "translate(43%, -70%)",
    fontFamily: "Montserrat",
    [theme.breakpoints.down("md")]: {
      width: 675,
    },
    [theme.breakpoints.down("sm")]: {
      width: 500,
      transform: "translate(10%, -20%)",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 260,
      transform: "translate(15%, -10%)",
    },
  },
  imageContainer: {
    width: 500,
    height: 287,
    marginLeft: "5%",

    [theme.breakpoints.down("md")]: {
      width: 365,
      height: 237,
    },
    [theme.breakpoints.down("sm")]: {
      width: 280,
      height: 187,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: 205,
      maxHeight: 137,
    },
  },

  feedback: {
    padding: "50px 0 50px 50px",
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },

  image: {
    width: "100%",
    height: "100%",
    position: "relative",
    zIndex: 1,
    backgroundColor: "#FFFFFF",
  },
  feedbackContentContainer: {
    maxWidth: 436,
    marginRight: theme.spacing(6),

    [theme.breakpoints.down("sm")]: {
      margin: `0 ${theme.spacing(4)}px`,
    },
  },
  customerLogo: {
    width: 140,
    height: 70,
    position: "absolute",
    transform: "translate(-65%,-440%)",
    zIndex: 10,
    [theme.breakpoints.down("md")]: {
      width: 95,
      height: 55,
    },
    [theme.breakpoints.down("sm")]: {
      width: 75,
      height: 45,
    },
    [theme.breakpoints.down("xs")]: {
      width: 50,
      height: 31,
      transform: "translate(-20%, -510%)",
    },
  },
  customerOpinion: {
    fontSize: 18,
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 14,
      marginTop: 15,
    },
  },
  customerName: {
    fontSize: 18,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
    fontWeight: theme.typography.semiBold,
  },
  entitlement: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 18,
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 11,
    },
  },
  knowLink: {
    color: "inherit",
    fontSize: "14px",
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
  },
  videoPlayIconContainer: {
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
    },
    "&:hover": {
      background: "#F07663",
    },
    background: "radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%)",
    border: "2px solid white",
    cursor: "pointer",
    outline: "none",
    borderRadius: 64,
    width: 64,
    height: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  videoPlayIcon: {
    [theme.breakpoints.down("sm")]: {
      borderWidth: "10px 0px 10px 15px",
    },
    borderStyle: "solid",
    borderWidth: "16px 0px 16px 26px",
    borderColor: "transparent transparent transparent white",
    marginLeft: 7,
  },

  caption: {
    marginTop: 30,
    fontFamily: "Montserrat, sans-serif",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      marginBottom: 30,
    },
  },
}));
const CustomerFeedback = (props) => {
  const {
    feedbackCaption,
    feedbackContentContainer,
    imageContainer,
    feedback,
    image,
    pageContainer,
    customerLogo,
    customerOpinion,
    customerName,
    entitlement,
    knowLink,
    videoPlayIcon,
    videoPlayIconContainer,
    caption,
  } = useStyles();
  const [playing, setPlaying] = useState(false);
  return (
    <div className={pageContainer}>
      <MainContainer>
        <Caption className={caption}>You are in a good company</Caption>
        <div className={feedback}>
          <div className={imageContainer}>
            <div className={image}>
              <ReactPlayer
                url={"https://www.youtube.com/watch?v=3k44UF3hBTk"}
                controls
                playing={playing}
                width="inherit"
                height="inherit"
                light={Feedback.imageSource}
                playIcon={
                  <button
                    onClick={() => setPlaying(true)}
                    className={videoPlayIconContainer}
                  >
                    <div className={videoPlayIcon}></div>
                  </button>
                }
              />
            </div>
            <img
              className={customerLogo}
              src={Feedback.logoImageSource}
              alt="Chroma logo"
            />
          </div>
          <div className={feedbackCaption}>
            <div className={feedbackContentContainer}>
              <Typography className={customerOpinion}>
                {Feedback.opinion}
              </Typography>
              <br />
              <div>
                <Typography className={customerName}>
                  {Feedback.customerName}
                </Typography>
                <Typography className={entitlement}>
                  {Feedback.customerEntitlement}
                </Typography>
                <br />
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  className={knowLink}
                  href="https://form.jotform.me/91042672900451"
                >
                  Know More
                </a>
              </div>
            </div>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

export default CustomerFeedback;
