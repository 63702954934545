import {TenantUsersModel} from "./users/TenantUsers.Model";
import {TenantBrandModel} from "./brand/TenantBrand.Model";

export class TenantInfoModel {
    public name: string;
    public esKey: string;
    public users: TenantUsersModel[];
    public brands: TenantBrandModel[];

    constructor(props: any) {
        this.name = props.BusinessName;
        this.esKey = props.EsKey;
        this.users = this.getUsers(props);
        this.brands = this.getBrands(props);
    }
    getUsers(props: any){
        return [new TenantUsersModel(props)];
    }
    getBrands(props: any){
        return [new TenantBrandModel(props)];
    }

}