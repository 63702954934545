export const bannerContent = {
  caption: "Execute loyalty for the new age consumer",
  medium: "RetenSee",
  content: `Omni-channel Loyalty Program that rewards both transactional and behavioral actions`,
  button: "Get In Touch",
  source: require("../../assets/Products/retenSee/Group 2715.svg"),
};

export const platformContent = {
  title: "RetenSee enables you to create completely digital loyalty program",
  caption:
    "A personalized and scalable loyalty solution for brands to get closer to customers.",
};

export const productCard = [
  {
    id: 1,
    title: "Increase brand value",
    list: [
      "Build loyalty programs that last",
      "Make your loyalty program omnichannel ",
      "Create personalized communications",
    ],
  },
  {
    id: 2,
    title: "Drive purchase frequency",
    list: [
      "Target your most valuable customers",
      "Increase the lifetime value of customers",
      "Ensure continuous participation",
    ],
  },
  {
    id: 3,
    title: "Seamlessly manage relationship & program",
    list: [
      "Easy integration with third party applications",
      "Manage interactions and redemptions easily",
      "Identify behaviours to build relationship",
    ],
  },
];

export const productTabList = [
  { id: 1, name: "Behaviours build relationships" },
  { id: 2, name: "Be engaged" },
  { id: 3, name: "Better ROI" },
];

export const productInfo = [
  {
    id: 1,
    source: require("../../assets/Products/retenSee/financial_analyst_monochromatic.svg"),
    content: {
      title: "Behaviours build relationships",
      details:
        "Loyal customers can earn and redeem rewards, no matter whatever the channel of interaction",
    },
  },
  {
    id: 2,
    source: require("../../assets/Products/retenSee/party_monochromatic (1).svg"),
    content: {
      title: "Be engaged",
      details:
        "Target behaviours and engage with customers, all the way down the marketing funnel",
    },
  },
  {
    id: 3,
    source: require("../../assets/Products/retenSee/data_analytics__monochromatic (1).svg"),
    content: {
      title: "Better ROI",
      details:
        "Bullet proof your returns and redemption process to achieve better and targeted ROI",
    },
  },
];

export const demoContents = {
  caption: "It is time to retain the Loyal customers",
  button: "Book a Demo",
};

export default {
  bannerContent,
  platformContent,
  productCard,
  productTabList,
  productInfo,
};
