import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { Carousel } from "react-responsive-carousel";
import clsx from "clsx";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  left: {
    left: 0,
    [theme.breakpoints.down("md")]: {
      left: -20,
    },
  },
  right: {
    right: 0,
    [theme.breakpoints.down("md")]: {
      right: -20,
    },
  },
  displayFix: {
    overflow: "hidden",
  },
}));

const SliderCarousel = (props) => {
  const { left, right, displayFix } = useStyles();

  return (
    <div className={displayFix}>
      <Carousel
        autoPlay={props.autoPlay}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <Button
              disableRipple
              size="small"
              onClick={onClickHandler}
              title={label}
              className={clsx(props.arrowStyles, left)}
            >
              <NavigateBefore fontSize="large" />
            </Button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <Button
              disableRipple
              size="small"
              onClick={onClickHandler}
              title={label}
              className={clsx(props.arrowStyles, right)}
            >
              <NavigateNext fontSize="large" />
            </Button>
          )
        }
        infiniteLoop
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        showArrows={false}
        selectedItem={props.currentSlide}
        useKeyboardArrows
        onChange={props.onChange}
      >
        {props.children}
      </Carousel>
    </div>
  );
};
export default SliderCarousel;
