import React from "react";
const { withStyles, Tabs, Tab } = require("@material-ui/core");

export const ProductTabs = withStyles((theme) => ({
  root: {
    borderBottom: "2px solid #70707075",
  },
  indicator: {
    backgroundColor: "#F07663",
    height: 7,
  },
}))(Tabs);

export const ProductCustomTab = withStyles((theme) => ({
  root: {
    letterSpacing: 1,
    minWidth: "33%",
    opacity: 1,
    color: "#1D2B37",
    fontSize: 20,
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: 14,
      height: "auto",
    },
    height: 90,
    fontWeight: theme.typography.semiBold,
    "&$selected": {
      transition: `all 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms`,
      color: "#F07663",
      fontWeight: 800,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);
