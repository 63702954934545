import React from "react";
import Helmet from "react-helmet";

const HeadTagDetails = () => {
  return (
    <>
      <Helmet>
        <title>Lead Management Software | Intelliclose</title>

        <meta
          name="description"
          lang="en"
          content="Drive more sales with our effective lead generation tool intelliclose &  make all the difference to your business"
        ></meta>
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Lead Management Software | Intelliclose"
        />
        <meta
          property="og:description"
          content="Drive more sales with our effective lead generation tool intelliclose &  make all the difference to your business"
        />
        <meta
          property="og:url"
          content="https://casaretail.ai/products/intelliclose"
        />
        <meta name="twitter:site" content="@casaretail_ai" />
        <meta
          name="twitter: title"
          content="Lead Management Software | Intelliclose"
        />
        <meta
          name="twitter:description"
          content="Drive more sales with our effective lead generation tool intelliclose &  make all the difference to your business"
        />
      </Helmet>
    </>
  );
};

export default HeadTagDetails;
