export const NavbarLinks = [
  { id: 1, pageLink: "/home", name: "Home" },
  { id: 2, pageLink: "/home", name: "Solutions" },
  { id: 3, pageLink: "/team", name: "Team" },
  { id: 4, pageLink: "/careers", name: "Careers" },
  { id: 5, pageLink: "/testimonial", name: "Case Studies" },
  { id: 6, pageLink: "/blogs", name: "Blog" },
];

export const Banner = {
  title: "Drive Retail Sales with our AI Powered Personalized CRM & Lifecycle Marketing Tool",
  imageSource: require("../assets/Home/pageconfigimage.png"),
  subTitle: `Personalized CRM & Lifecycle Marketing Tool `,
  featureList: [
    "Auto Creates Customer Segments",
    "Recommends Personalized Offers, Engagement at each Touchpoint of the Journey",
    "Create Delightful Experiences in Stores and on Digital Platforms",
  ],
};

export const BenifitedLogos = [
  {
    id: 1,
    source: require("../assets/Home/Clients_Logo/Group 3104.png"),
  },
  {
    id: 2,
    source: require("../assets/Home/Clients_Logo/Group 3132.png"),
  },
  /*	{
		id: 3,
		source: require("../assets/Home/Clients_Logo/lots_wholesale_solutions_cp_wholesale_india_private_limited___a_siam_makro_enterprise_-removebg-preview.png"),
	},*/
  {
    id: 4,
    source: require("../assets/Home/Clients_Logo/Group 3135.png"),
  },

  {
    id: 5,
    source: require("../assets/Home/Clients_Logo/Group 3134.png"),
  },
  {
    id: 6,
    source: require("../assets/Home/Clients_Logo/darling.png"),
  },
  {
    id: 7,
    source: require("../assets/Home/Clients_Logo/Group 3133.png"),
  },
  {
    id: 8,
    source: require("../assets/Home/Clients_Logo/Group 3139.png"),
  },
  {
    id: 11,
    source: require("../assets/Home/Clients_Logo/Group 3136.png"),
  },
  {
    id: 9,
    source: require("../assets/Home/Clients_Logo/Group 3137.png"),
  },
  {
    id: 10,
    source: require("../assets/Home/Clients_Logo/Group 3138.png"),
  },
];

export const Feedback = {
  imageSource: require("../assets/Home/pexels-andrea-piacquadio-842567.png"),
  logoImageSource: require("../assets/Home/Image 1.png"),
  opinion: `“ We saw 400% increase in ROI and 35 basis point increase in sales after we
	started using CASA ”`,
  customerName: "Kapil Budukh",
  customerEntitlement: "General Manager - Marketing, Croma",
};
export const SubProductTitle = `Adding Value in each stage of the Customer Lifecycle`;
export const Activities = [
  { id: 1, name: "AWARE" },
  { id: 2, name: "ATTRACT" },
  { id: 3, name: "TRANSACT" },
  { id: 4, name: "RETAIN" },
];

export const AwardList = [
  {
    id: 1,
    source: require("../assets/Home/0.41f205d0-removebg-preview.png"),
  },
  { id: 2, source: require("../assets/Home/60882360.png") },
  { id: 3, source: require("../assets/Home/Image 50@2x.png") },
  { id: 4, source: require("../assets/Home/Image 51@2x.png") },
];

export const StatIcons = [
  {
    id: 1,
    source: require("../assets/Home/icons8-city-buildings-100.png"),
    header: "10X",
    discription: "ROI",
  },
  {
    id: 2,
    source: require("../assets/Home/icons8-find-user-male-100.png"),
    header: "4X",
    discription: "Customer Retention",
  },
  {
    id: 3,
    source: require("../assets/Home/icons8-small-business-100.png"),
    header: "100%",
    discription: "Major Brand Retention",
  },
  {
    id: 4,
    source: require("../assets/Home/icons8-business-building-100.png"),
    header: "14 Million",
    discription: "Delighted Consumers",
  },
];
export const Products = [
  {
    id: 1,
    path: "/products/intelliclose",
    imgSrc: require("../assets/Home/Intelliclose.png"),
    header: "Intelliclose",
    discription: "Lead Management",
  },
  {
    id: 2,
    path: "/products/ubicuity",
    imgSrc: require("../assets/Home/UbicuityCasa.png"),
    header: "Ubicuity",
    discription: "Customer Data Platform",
  },
  {
    id: 3,
    path: "/products/journify",
    imgSrc: require("../assets/Home/JournifyLogo.png"),
    header: "Journify",
    discription: "Personalized Customer Engagement",
  },
  // {
  //   id: 4,
  //   path: "/products/pwa",
  //   imgSrc: require("../assets/Home/Group 2280.svg"),
  //   header: "PWA",
  //   discription: "Retail Insights",
  // },
  {
    id: 4,
    path: "/products/offerio",
    imgSrc: require("../assets/Home/OfferioCasa.png"),
    header: "Offerio",
    discription: "Offer management",
  },
  {
    id: 5,
    path: "/products/retenSee",
    imgSrc: require("../assets/Home/RetenseeCasa.png"),
    header: "RetenSee",
    discription: "Loyalty Management",
  },
  {
    id: 6,
    path: "/products/pimkart",
    imgSrc: require("../assets/Home/PimkartCasa.svg"),
    header: "PIMkart",
    discription: "Product Information Management",
  },
  {
    id: 7,
    path: "/products/flipSell",
    imgSrc: require("../assets/Home/FlipsellCasa.png"),
    header: "FlipSell",
    discription: "Personalized Digital Catalog ",
  },
  // {
  //   id: 8,
  //   path: "/products/fieldSalesAssist",
  //   imgSrc: require("../assets/Home/Group 2285.svg"),
  //   header: "Field Sales Assist",
  //   discription: "Sales Force Activity Manangement",
  // },
];

export const SubProductsContent = [
  {
    id: 1,
    Header: "Targeted Personalised engagement",
    Discription: `Actionable Intelligence from every customer touchpoint to target and re-target consumers`,
    imageSource: require("../assets/Home/pr_monochromatic (1).svg"),
    SubProductLinks: [...Products],
  },
  {
    id: 2,
    Header: "Experience Driven Hassle Free Next Gen Social and E-commerce",
    Discription: "A personalized journey enabling greater conversions on preferred digital channels",
    imageSource: require("../assets/Home/target_audience__monochromatic (1).svg"),
    SubProductLinks: [...Products],
  },
  {
    id: 3,
    Header: "Insignt Driven Auromated process to retain customers",
    Discription: "Improve purchase frequency and increase average ticket size",
    imageSource: require("../assets/Home/finance_analytics__monochromatic (1).svg"),
    SubProductLinks: [...Products],
  },
  {
    id: 4,
    Header: "Bring back your at-risk and droped-off customers",
    Discription: "Arrest drop offs through intelligent recommendations and personalized communication.",
    imageSource: require("../assets/Home/achievement__monochromatic (1).svg"),
    SubProductLinks: [...Products],
  },
];

export const SubscriptionContent = {
  title: "Adding Value in each stage of the Customer Lifecycle",
  subtitle: "Enter your email and will get back to you",
};

export const PartnerLogos = [
  {
    id: 1,
    source: require("../assets/Home/Screenshot_2020-09-23_at_11.43.53_AM-removebg copy.png"),
  },
  {
    id: 2,
    source: require("../assets/Home/Screenshot_2020-09-23_at_11.43.53_AM-removebg copy 2.png"),
  },
  {
    id: 3,
    source: require("../assets/Home/Screenshot_2020-09-23_at_11.43.53_AM-removebg copy 3.png"),
  },
  {
    id: 4,
    source: require("../assets/Home/Screenshot_2020-09-23_at_11.43.53_AM-removebg copy 4.png"),
  },
  {
    id: 5,
    source: require("../assets/Home/Screenshot_2020-09-23_at_11.43.53_AM-removebg copy 5.png"),
  },
  {
    id: 6,
    source: require("../assets/Home/Screenshot_2020-09-23_at_11.56.44_AM-removebg-preview (1).png"),
  },
  {
    id: 7,
    source: require("../assets/Home/Screenshot_2020-09-23_at_11.56.44_AM-removebg-preview (2).png"),
  },
  {
    id: 8,
    source: require("../assets/Home/Screenshot_2020-09-23_at_11.56.44_AM-removebg-preview (3).png"),
  },
  {
    id: 9,
    source: require("../assets/Home/Screenshot_2020-09-23_at_11.56.44_AM-removebg-preview (4).png"),
  },
  {
    id: 10,
    source: require("../assets/Home/Screenshot_2020-09-23_at_11.56.44_AM-removebg-preview.png"),
  },
];
export const FooterTitle = `One Stop Solution for all your customer engagement`;

export const FooterDetails = [
  {
    id: 1,
    summary: "Products",
    details: [
      ...Products.slice(0, 4).map((value) => ({
        id: value.id,
        name: value.header,
        link: value.path,
      })),
    ],
  },
  {
    id: 2,
    summary: "Products",
    details: [
      ...Products.slice(4).map((value) => ({
        id: value.id,
        name: value.header,
        link: value.path,
      })),
    ],
  },
  {
    id: 3,
    summary: "Company",
    details: [
      {
        id: 1,
        link: "/careers",
        name: "Careers",
      },
      {
        id: 2,
        link: "/team",
        name: "Our Team",
      },
      // {
      // 	id: 3,
      // 	link: "/privacyPolicy",
      // 	name: "Privacy  Policy",
      // },
    ],
  },
];
export const ProductsSummary = {
  id: 1,
  summary: "Solutions",
  details: [
    ...Products.map((value) => ({
      id: value.id,
      name: value.header,
      link: value.path,
    })),
  ],
};
export const MobileFooterDetails = [
  {
    ...ProductsSummary,
  },
  {
    id: 2,
    summary: "Company",
    details: [
      {
        id: 1,
        href: "https://www.ajira.tech/careers/",
        name: "Careers",
      },
      { id: 2, link: "/team", name: "Our Team" },
    ],
  },
];

export const registerButtonContainerText =
  "CASA will enable you to implement your customer engagement goals and success plan that can put you on a path to consistent growth. Want to talk to our consultant?";

export const subProductListContent = {
  title: `Manage Your Customer Life Cycle with CASA CDP & AI Engine`,
  subTitle: `Get our expert guidance to adopt a data driven approach to automate engagements at all touch points
  of your customer journey with Casa AI engine and tools`,
  body: `Acquire new customers from POS registration, walk-in capture in the lead management system, customer reference and online sign up `,
};

export const subProductListItems = [
  {
    productId: 201,
    title: `Acquire new customers from POS registration, walk-in capture in the lead management system, customer reference and online sign up `,
    items: [
      {
        image: require("../assets/Home/Intelliclose.png"),
        title: "Intelliclose",
        content: `Capture walk-in leads to boost sales
    using AI driven LMS`,
        // path: "/products/intelliclose",
      },
      {
        image: require("../assets/Home/PimkartCasa.svg"),
        title: "PIMkart",
        content: `Sign up on commerce portal, mobile
    or other channels`,
        // path: "/products/pimkart",
      },
      {
        image: require("../assets/Home/posregistration.svg"),
        title: "POS Registration",
        content: `Customer data sync from
    POS transaction`,
        // path: "/products/pimkart",
      },
      {
        image: require("../assets/Home/OfferioCasa.png"),
        title: "Offerio",
        content: `Flexible offer generator at product, Customer, Store, Period level`,
        path: "/products/offerio",
      },
      //   {
      //     image: require("../assets/Home/customercube.svg"),
      //     title: `PWA`,
      //     content: `AI powered customer data
      // platform and analytics`,
      //   },
      {
        image: require("../assets/Home/socialconnect.svg"),
        title: "Social Connect",
        content: `Connect on social media to reach
    out to new customers`,
        // path: "/products/pimkart",
      },
      {
        image: require("../assets/Home/customerapp.svg"),
        title: `Customer App`,
        content: `Get new leads from reference
    from existing customers`,
        // path: "/products/pimkart",
      },
    ],
  },
  {
    productId: 202,
    title: `Retail existing business with timely, personalized messages using omnichannel marketing at each touch point of your customer’s shopping journey`,
    items: [
      {
        image: require("../assets/Home/RetenseeCasa.png"),
        title: "Retensee",
        content: `Omni-channel Loyalty Program that rewards both transactional and behavioral actions`,
      },
      {
        image: require("../assets/Home/JournifyLogo.png"),
        title: "Journey Builder",
        content: `Personalize and automate customer engagement based on their actions on omni-platform`,
      },
      {
        image: require("../assets/Home/referral.svg"),
        title: "NPS",
        content: `Build an army of brand promoters with timely feedback from customers`,
      },
      // {
      //   image: require("../assets/Home/customercube.svg"),
      //   title: `PWA`,
      //   content: `Identify actionable insights with AI powered customer data platform and analytics`,
      // },
      {
        image: require("../assets/Home/FlipsellCasa.png"),
        title: "FlipSell",
        content: `Send personalized catalogs to regular customers to ensure high repeat purchase`,
      },
      {
        image: require("../assets/Home/OfferioCasa.png"),
        title: "Offerio",
        content: `Flexible offer generator at product, Customer, Store, Period level`,
      },
    ],
  },
  {
    productId: 203,
    title: `Convert your customers into brand advocates. Delight them with personalized engagement, privilege services and custom built rewards. Encourage them to spread the word of mouth `,
    items: [
      {
        image: require("../assets/Home/Social_rewards.svg"),
        title: "Social Rewards",
        content: `Reward your customer for social share, likes, reviews and other behaviors`,
      },
      {
        image: require("../assets/Home/UbicuityCasa.png"),
        title: "Ubicuity",
        content: `Improve Customer Satisfaction with delighful customer experience`,
      },
      {
        image: require("../assets/Home/referral.svg"),
        title: "Referral",
        content: `Give your advocates opportunity to refer friends and earn rewards`,
      },
      // {
      //   image: require("../assets/Home/customercube.svg"),
      //   title: `PWA`,
      //   content: `Identify actionable insights with AI powered customer data platform and analytics `,
      // },
      {
        image: require("../assets/Home/FlipsellCasa.png"),
        title: "FlipSell",
        content: `Deliver seasonal catalogs to festive customer cohorts in advance and stay ahead of competition`,
      },
      {
        image: require("../assets/Home/Customer-App.svg"),
        title: "Customer App",
        content: `Serve your customers with custom imformation to build deep relationship`,
      },
      {
        image: require("../assets/Home/OfferioCasa.png"),
        title: "Offerio",
        content: `Flexible offer generator at product, Customer, Store, Period level`,
      },
    ],
  },

  {
    productId: 204,
    title: `Grow your pool of customers both in size and in revenue using CDP analytics and casa recommendation engine `,
    items: [
      {
        image: require("../assets/Home/OfferioCasa.png"),
        title: "Offerio",
        content: `Flexible offer generator at product, Customer, Store, Period level`,
      },
      {
        image: require("../assets/Home/JournifyLogo.png"),
        title: "Journify",
        content: `Automate and personalize your sales campaign using insights`,
      },
      {
        image: require("../assets/Home/socialcommerce.svg"),
        title: "Social Commerce",
        content: `Using social media to drive sales and engagement`,
      },
      //   {
      //     image: require("../assets/Home/customercube.svg"),
      //     title: `PWA`,
      //     content: `AI powered customer data
      // platform and analytics`,
      //   },
      {
        image: require("../assets/Home/FlipsellCasa.png"),
        title: "FlipSell",
        content: `Increase conversations using Flipsell Personalized Digital catalog`,
      },
    ],
  },

  {
    productId: 205,
    items: [
      {
        image: require("../assets/Home/Intelliclose.png"),
        title: "Intelliclose",
        content: `Capture walk-in leads to boost sales
    using AI driven LMS`,
      },
      {
        image: require("../assets/Home/omnicommerce.svg"),
        title: "PIMkart",
        content: `Sign up on commerce portal, mobile
    or other channels`,
      },
      {
        image: require("../assets/Home/posregistration.svg"),
        title: "POS Registration",
        content: `Customer data sync from
    POS transaction`,
      },
      //   {
      //     image: require("../assets/Home/customercube.svg"),
      //     title: `PWA`,
      //     content: `AI powered customer data
      // platform and analytics`,
      //   },
      {
        image: require("../assets/Home/socialconnect.svg"),
        title: "Social Connect",
        content: `Connect on social media to reach
    out to new customers`,
      },
      {
        image: require("../assets/Home/customerapp.svg"),
        title: `Customer App`,
        content: `Get new leads from reference
    from existing customers`,
      },
    ],
  },
];

export const AwardsList2 = [
  {
    id: 1,
    source: require("../assets/Home/award1.png"),
  },
  { id: 2, source: require("../assets/Home/award2.png") },
  { id: 3, source: require("../assets/Home/award3.png") },
  { id: 3, source: require("../assets/Home/award4.png") },
];

export const subProductIcon = [
  {
    id: 201,
    inActive: require("../assets/Home/acquire.png"),
    active: require("../assets/Home/acquire2.png"),
    name: "Acquire",
  },
  {
    id: 202,
    inActive: require("../assets/Home/retain.png"),
    active: require("../assets/Home/retain2.png"),
    name: "Retain",
  },
  {
    id: 203,
    inActive: require("../assets/Home/delight.png"),
    active: require("../assets/Home/delight2.png"),
    name: "Delight",
  },
  {
    id: 204,
    inActive: require("../assets/Home/grow.svg"),
    active: require("../assets/Home/grow2.svg"),
    name: "Grow",
  },
];
