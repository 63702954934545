import { makeStyles } from "@material-ui/core";
import React from "react";
import { Banner } from "../../content/careers";
import HeroButton from "../../components/heroBtn";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "300px",
    width: "100%",
    backgroundColor: "#f4f5f7",
    fontFamily: "'Nunito'",
    padding: "6%  0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 44,
    fontWeight: 900,
    [theme.breakpoints.down("sm")]: {
      fontSize: 38,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 23,
    },
  },
  subTitle: {
    fontSize: 44,
    fontWeight: 900,
    color: "#F07663",
    [theme.breakpoints.down("sm")]: {
      fontSize: 38,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 23,
      marginTop: "5px",
    },
  },
  about: {
    fontSize: 20,
    margin: 24,
    width: "50%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      fontSize: 18,
    },
  },
  btn: {
    marginTop: 24,
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      margin: 10,
    },
  },
}));

const Pagecaption = (props) => {
  const { root, title, subTitle, about, btn } = useStyles();
  return (
    <>
      <div className={root}>
        <div className={title}>{Banner.title}</div>
        <div className={subTitle}>{Banner.subTitle}</div>
        <div className={about}>{Banner.about}</div>
        <a href="#openingsSection" className={btn}>
          <HeroButton>View Openings</HeroButton>
        </a>
      </div>
    </>
  );
};

export default Pagecaption;
