import config from "../../config";

export const bannerContent = {
  caption: "Drive sales using our digital catalog",
  medium: "FlipSell",
  content: `Send personalized catalogs to regular customers to ensure high repeat purchase`,
  button: "SIGN UP FOR FREE",
  source: require("../../assets/Products/flipSell/undraw_Books_l33t.svg"),
  redirect: `${config.casaWebsiteUrl}/signup`,
};

export const platformContent = {
  title: "FlipSell connects your customers to amazing content",
  caption:
    "A shareable high-quality digital catalog to showcase products across digital platforms",
};

export const productCard = [
  {
    id: 1,
    title: "Easy e-catalogues",
    list: [
      "Satiate customer needs with swift e-catalogues",
      "Drag-and-drop interface allowing you to create the content you need",
    ],
  },
  {
    id: 2,
    title: "Build conversations",
    list: [
      "Engage with shoppers directly on the digital catalog",
      "Customers have the ability to connect with store staff when required",
    ],
  },
  {
    id: 3,
    title: "Start converting",
    list: [
      "Engage and convert on the catalog",
      "Create faster sell through with interactive content",
      "Mobile-optimized pages",
    ],
  },
];

export const productTabList = [
  { id: 1, name: "High-quality content" },
  { id: 2, name: "Run campaigns" },
  { id: 3, name: "Analyse performance" },
];

export const productInfo = [
  {
    id: 1,
    source: require("../../assets/Products/flipSell/content_creation (1).svg"),
    content: {
      title: "High-quality content",
      details:
        "By incorporating beautiful content, creative and media assets, you can start engaging with customers right away and individually",
    },
  },
  {
    id: 2,
    source: require("../../assets/Products/flipSell/email_campaign.svg"),
    content: {
      title: "Run campaigns",
      details:
        "FlipSell catalogs are shareable on WhatsApp, Facebook, Twitter and more. It makes a perfect marketing collateral",
    },
  },
  {
    id: 3,
    source: require("../../assets/Products/flipSell/data_analytics_.svg"),
    content: {
      title: "Analyse performance",
      details:
        "Fully integrated with Ubicuity and PWA modules to provide an enriched customer profile",
    },
  },
];

export const demoContents = {
  caption: "Come lets personalize digital brochure with a click",
  button: "Book a Demo",
};

export default {
  bannerContent,
  platformContent,
  productCard,
  productTabList,
  productInfo,
};
