export const bannerContent = {
  caption: "Convert window-shoppers to customers",
  medium: "Intelliclose",
  content: `Capture walk-in leads to boost sales using AI driven LMS`,
  button: "Get In Touch",
  source: require("../../assets/Products/intelliclose/undraw_successful_purchase_uyin.svg"),
  path: "/products/intelliclose",
};

export const platformContent = {
  title: "Intelliclose gives the nudge",
  caption:
    "Knowing the leads that matter makes all the difference to your business",
};

export const productCard = [
  {
    id: 1,
    title: "An engaged shopper",
    list: [
      "AI-driven lead scoring",
      "Non-intrusive",
      "Accelerate your response",
    ],
  },
  {
    id: 2,
    title: "An efficient store",
    list: [
      "Pinpoint skill needs",
      "Manage pipeline better",
      "Prioritized leads for sales",
    ],
  },
  {
    id: 3,
    title: "Experience AI Advantage",
    list: [
      "Gain lead and management insights ",
      "Consistent delightful engagement",
      "Focus on areas that needs attention",
    ],
  },
];

export const productTabList = [
  { id: 1, name: "Easily engage with shoppers" },
  { id: 2, name: "Create delight in a jiffy!" },
  { id: 3, name: "Manage business better" },
];

export const productInfo = [
  {
    id: 1,
    source: require("../../assets/Products/SubProduct/Image 74.png"),
    content: {
      title: "Easily engage with shoppers",
      details:
        "Knowing what actions to take to convert a lead is no longer a hit-and-miss job. Intelliclose gives you these insights along with automated personalized engagement ability",
    },
  },
  {
    id: 2,
    source: require("../../assets/Products/intelliclose/shopping_monochromatic (1).svg"),
    content: {
      title: "Create delight in a jiffy!",
      details:
        "Intelliclose gives you the advantage to get close to your customers without the hassle. It extracts insights to help you take the next right move",
    },
  },
  {
    id: 3,
    source: require("../../assets/Products/intelliclose/report_analysis__monochromatic (1).svg"),
    content: {
      title: "Manage business better",
      details:
        "Manage stores and leads better with our Kanban board and unique lead scoring AI to focus better on the leads that matter",
    },
  },
];

export const demoContents = {
  caption: "It is time to increase conversions from footfalls",
  button: "Book a Demo",
};

export default {
  bannerContent,
  platformContent,
  productCard,
  productTabList,
  productInfo,
};
