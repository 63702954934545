import React from "react";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { registerButtonContainerText } from "../../content/Home";
import MainContainer from "../../components/mainContainer";

const useStyles = makeStyles((theme) => ({
  registerContainer: {
    height: 200,
    backgroundColor: "#4C8F83",
    marginTop: "6%",

    [theme.breakpoints.down("sm")]: {
      height: 300,
      marginTop: "15%",
    },
  },

  registerContainer2: {
    display: "flex",
    // justifyContent: "space-between",
    // padding: "0 0 0 7%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  startedText: {
    height: 180,
    width: 270,
    backgroundColor: "#ffff",
    boxShadow: "0 0 10px grey",
    marginTop: "-50px",
    marginRight: "8%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      height: 130,
      width: 160,
    },
  },
  startedTextContent: {
    color: "#F07663",
    fontFamily: "Montserrat, sans-serif",
    textShadow: "0px 2px, 2px 0px, 2px 2px;",
    fontWeight: "900 !important",
    fontSize: 47,
    letterSpacing: "2px",
    paddingLeft: 26,

    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      padding: 22,
      textShadow: "0px 1px, 1px 0px, 1px 1px;",
    },
  },
  mobilestartedTextContent: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      padding: 22,
      fontWeight: "bolder",
      // textShadow: "0px 1px, 1px 0px, 1px 0px;",
      color: "#4C8F83",
      fontFamily: "Montserrat, sans-serif",
      // textShadow: "0px 2px, 2px 0px, 2px 2px",
      // fontWeight: "bolder !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      lineHeight: 1.5,
      letterSpacing: "2px",
    },
  },
  mobileStartedText: {
    [theme.breakpoints.down("sm")]: {
      height: 200,
      width: 250,
      backgroundColor: "#ffff",
      boxShadow: "1px 1px 5px #666666",
      marginTop: "-50px",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },

  registerButtonContainer: {
    width: "60%",
    padding: "40px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "25px 0",
    },
  },
  registerButtonContainerTextStyle: {
    color: "#ffff",
    fontWeight: 600,
    fontSize: 18,
    paddingBottom: 20,

    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      padding: "0 10px",
      fontWeight: 600,
      textAlign: "center",
    },
  },
  registerButton: {
    border: "none",
    borderRadius: "5px",
    padding: 10,
    fontSize: 18,
    fontWeight: "bold",
    backgroundColor: "white",
    fontFamily: "Montserrat, sans-serif",
    cursor: "pointer",
    "&:hover": {
      color: "#F07663",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      padding: 7,
    },
  },

  link: {
    textDecoration: "none",
  },
  bookDemo: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      marginTop: 20,
    },
  },
}));

function Register() {
  const {
    registerContainer,
    registerContainer2,
    startedText,
    startedTextContent,
    registerButtonContainer,
    mobilestartedTextContent,
    mobileStartedText,
    registerButtonContainerTextStyle,
    registerButton,
    bookDemo,
  } = useStyles();
  const mobileMatcher = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <div className={registerContainer}>
      <MainContainer>
        <div className={registerContainer2}>
          <div className={mobileMatcher ? mobileStartedText : startedText}>
            <div
              className={
                mobileMatcher ? mobilestartedTextContent : startedTextContent
              }
            >
              {mobileMatcher
                ? registerButtonContainerText
                : "Let's get started"}
            </div>
          </div>
          <div className={registerButtonContainer}>
            <div className={registerButtonContainerTextStyle}>
              {mobileMatcher
                ? "Let's get started"
                : registerButtonContainerText}
            </div>
            <div className={bookDemo}>
              <button
                className={registerButton}
                onClick={() =>
                  // window.ml_account("webforms", "5211053", "u6u0x4", "show")
                  window.location.assign("https://docs.google.com/forms/d/e/1FAIpQLSdRMpCs2uRl4fK87sQQbDRLsNc_eu14Ya_ERyDa__gtUUPg5w/viewform")
                }
              >
                Book a Demo
              </button>
            </div>
          </div>
        </div>
      </MainContainer>
    </div>
  );
}

export default Register;
