import React, { useEffect } from 'react';

import Banner from './NewHome/Banner';
import NavBar from './NewHome/NavBar';
import styles from './Home.module.scss';

const Home = () => {
  useEffect(() => {
    window.document.body.style.background =
      'linear-gradient(180deg, #0F1428 37.76%, #182044 100%)';
    return () => (window.document.body.style.background = '#fff');
  }, []);

  return (
    <>
      <div className={styles.container}>
        {Array.from({ length: 100 }).map((_, i) => (
          <div key={i} className={styles.circleContainer}>
            <div class={styles.circle}></div>
          </div>
        ))}
      </div>
      <NavBar />
      <Banner />
    </>
  );
};

export default Home;
