import customerCube from "../assets/Home/newHome/customCub.svg";
import campaign from "../assets/Home/newHome/campaign.svg";
import offerio from "../assets/Home/newHome/offerio.svg";
import retense from "../assets/Home/newHome/retense.svg";
import personify from "../assets/Home/newHome/personify.svg";
import digitalReceipt from "../assets/Home/newHome/digitalReceipt.svg";

export const modules = [
  {
    logo: customerCube,
    title: "Customer Cube",
    subtitle: "360 View of your Business",
    points: [
      "Predictive and Prescriptive Analytics",
      "Swiftly identify and act on root cause",
      "AI-aided Segmentation",
      "Customizable Dashboards",
    ],
  },
  {
    logo: offerio,
    title: "Offerio",
    subtitle: "Cutting-edge Offers Module",
    points: [
      "Easily create cross-sell, tiered and family offers",
      "Automatically picks best offer combinations for each purchase",
      "Set up multi-tiered and segment-specific offers",
      "Compare Offer Performance and ROI",
    ],
  },
  {
    logo: retense,
    title: "Retensee",
    subtitle: "Truly Omnichannel Loyalty",
    points: [
      "Multi-factor Tier Computation",
      "Enhanced Reporting",
      "Real-time customer-specific schemes",
      "Event-based rewards",
    ],
  },
  {
    logo: campaign,
    title: "Campaign Delight",
    subtitle: "Revolutionized Retail Marketing",
    points: [
      "Run A/B and Multivariant Campaigns",
      "Target Across Traditional and Social Channels",
      "Reach a Million Customers within minutes",
      "Capture your True ROI using Success Metrics",
    ],
  },
  {
    logo: personify,
    title: "Personify",
    subtitle: "Immersive Consumer Experience",
    points: [
      "Effortless Customer Enrollment",
      "Your Customer's Digital Shopping Buddy",
      "Comply with Data Privacy Laws",
      "Transform Shopper Connect",
    ],
  },
  {
    logo: digitalReceipt,
    title: "Digital Receipt",
    subtitle: "Simplify, Save & Succeed",
    points: [
      "Go Green with New Age Interactive Receipts",
      "Seamlessly Capture Customer Experience",
      "Increase Average Visit Value",
      "Instant Personalized Recommendations",
    ],
  },
];
