import React from "react";
import { makeStyles, Grid } from "@material-ui/core";
import HeroButton from "../../components/heroBtn";
import Caption from "../../components/caption";

const useStyles = makeStyles((theme) => ({
  bannerContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 20px 15px",
      width: "100%",
    },
    marginTop: 10,
  },
  bannerImageContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  bannerImage: {
    width: "100%",
  },
  bannerDescriptionContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  captionContainer: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: "unset",
    },
    width: 502,
    height: 50,
  },
  caption: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 32,
      textAlign: "center",
    },
    textAlign: "left",
    lineHeight: 1.2,
  },
  sloganContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: 16,
      textAlign: "center",
      height: "unset",
      margin: "20px 0px",
    },
    width: 464,
    height: 70,
    fontSize: 20,
    fontWeight: theme.typography.fontWeightMedium,
  },
  bannerButtonContainer: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  bannerButton: {
    width: 151,
    height: 54,
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
}));

const Banner = () => {
  const classes = useStyles();
  const {
    bannerContainer,
    bannerImageContainer,
    bannerImage,
    bannerDescriptionContainer,
    captionContainer,
    caption,
    sloganContainer,
    bannerButtonContainer,
    bannerButton,
    link,
  } = classes;

  return (
    <Grid container className={bannerContainer}>
      <Grid item lg={6} md={6} sm={6} xs={12} className={bannerImageContainer}>
        <img
          className={bannerImage}
          src={require("../../assets/Testimonial/undraw_celebration_0jvk.png")}
          alt="undraw celebration"
        />
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={6}
        xs={12}
        className={bannerDescriptionContainer}
      >
        <div>
          <div className={captionContainer}>
            <Caption className={caption}>
              You are gonna use the Right Product
            </Caption>
          </div>
          <div className={sloganContainer}>
            Because already many business have grown their revenue with us
          </div>
          <div className={bannerButtonContainer}>
            <HeroButton className={bannerButton}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                className={link}
                href="https://form.jotform.me/91042672900451"
              >
                Leverage now
              </a>
            </HeroButton>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default Banner;
