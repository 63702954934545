export const bannerContent = {
  caption: "Get a 360° view of your customer",
  medium: "Ubicuity",
  content: `Improve Customer Satisfaction with delighful customer experience`,
  button: "Get In Touch",
  source: require("../../assets/Products/ubicuity/personal_data_.svg"),
};

export const platformContent = {
  title: "Ubicuity personalizes customer engagement",
  caption:
    "Treat each customer personally at scale with authentic AI-based customer jathak",
};

export const productCard = [
  {
    id: 1,
    title: "Customer Centricity",
    list: ["Gather customer touchpoints across stores, online and social "],
  },
  {
    id: 2,
    title: "CASA’s Unique ADB ",
    list: [
      "ADB calculated for every customer",
      "RFM + ADB provides hyper personalization",
    ],
  },
  {
    id: 3,
    title: "Omni Channel Insights",
    list: [
      "Channel specific insights and journey ",
      "Omni channel insights and journey",
    ],
  },
];

export const productTabList = [
  { id: 1, name: "Track customer journey" },
  { id: 2, name: "Pinpoint your target customer" },
  { id: 3, name: "Zoom in on your opportunities" },
];

export const productInfo = [
  {
    id: 1,
    source: require("../../assets/Products/ubicuity/analytics_process_monochromatic.svg"),
    content: {
      title: "Track customer journey",
      details:
        "Ubicuity reconciles data from multiple customer touchpoints to create holistic customer profiles and needs in their journey",
    },
  },
  {
    id: 2,
    source: require("../../assets/Products/ubicuity/data_storage.svg"),
    content: {
      title: "Pinpoint your target customer",
      details:
        "Connecting the dots across channels coupled with predictive  modelling creates pinpoint engagement",
    },
  },
  {
    id: 3,
    source: require("../../assets/Products/ubicuity/information_flow_monochromatic.svg"),
    content: {
      title: "Zoom in on your opportunities",
      details:
        "CASA provides you with the ability to zoom into your business and creates highlighted opportunity areas",
    },
  },
];

export const demoContents = {
  caption: "Let's understand customer in a wholistic way",
  button: "Book a Demo",
};

export default {
  bannerContent,
  platformContent,
  productCard,
  productTabList,
  productInfo,
};
