import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { craftsmanList } from "../content/Team";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "whitesmoke",
    opacity: "0.23 ",

    "& > .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },

    "& > div.MuiDialog-scrollPaper > div.MuiDialog-paperScrollPaper": {
      backgroundColor: "transparent",
    },
  },
  appBar: {
    display: "flex",
  },

  closeBtn: {
    marginLeft: "auto",
    color: "#1976d2",
    [theme.breakpoints.down("sm")]: {
      padding: " 8px",
      marginTop: "40px",
    },
  },
  dialogBody: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 64,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
      padding: 8,
    },
  },
  profile: {
    display: "flex",
    justifyContent: "center",
    height: 150,
    width: 150,
    borderRadius: 50,
    [theme.breakpoints.down("sm")]: {
      height: 120,
      width: 120,
    },
  },
  profileImg: {
    width: "100%",
    borderRadius: "3px",
  },
  question: {
    fontSize: "18px",
    fontWeight: "bold",
    fontFamily: "'Nunito'",
    marginTop: 24,
    marginBottom: 4,
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
      fontSize: 18,
      textAlign: "center",
    },
  },
  answer: {
    fontSize: "16px",
    fontFamily: "'Nunito'",
    // margin: 2,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: 15,
    },
  },
  craftsmanName: {
    marginTop: 12,
    fontWeight: theme.typography.semiBold,
    fontFamily: "Montserrat",
    fontSize: "24px",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
    },
  },
  craftsmanPosition: {
    padding: 10,
    fontSize: "20px",
    fontFamily: "Montserrat",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
  const {
    root,
    appBar,
    closeBtn,
    dialogBody,
    profile,
    craftsmanName,
    craftsmanPosition,
    profileImg,
    question,
    answer,
  } = useStyles();

  const { craftmanId, open, handleClose } = props;

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={root}
      >
        <div className={appBar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className={closeBtn}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {craftmanId !== "" && (
          <div className={dialogBody}>
            <div className={profile}>
              <img
                src={craftsmanList[craftmanId].source}
                alt={craftsmanList[craftmanId].name}
                className={profileImg}
              />
            </div>
            <div className={craftsmanName}>
              {craftsmanList[craftmanId].name}
            </div>
            <div className={craftsmanPosition}>
              {craftsmanList[craftmanId].position}
            </div>
            <div className={question}>{craftsmanList[craftmanId].Qone}</div>
            <div className={answer}>{craftsmanList[craftmanId].Aone}</div>
            <div className={question}>{craftsmanList[craftmanId].Qtwo}</div>
            <div className={answer}>{craftsmanList[craftmanId].Atwo}</div>
            <div className={question}>{craftsmanList[craftmanId].Qthree}</div>
            <div className={answer}>{craftsmanList[craftmanId].Athree}</div>
            <div className={question}>{craftsmanList[craftmanId].Qfour}</div>
            <div className={answer}>{craftsmanList[craftmanId].Afour}</div>
          </div>
        )}
      </Dialog>
    </div>
  );
}
