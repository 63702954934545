import React from "react";
import ReactPlayer from "react-player";
import { Grid, makeStyles, Chip, Typography, Card } from "@material-ui/core";
import { customerFeedbackList } from "../../content/Testimonial";

const useStyles = makeStyles((theme) => ({
  customerFeedbackContainer: {
    background: "#F4F4F47E",
  },
  customerFeedbackGridContainer: {
    [theme.breakpoints.down("sm")]: {
      height: 528,
      display: "flex",
      flexDirection: "column-reverse",
      justifyContent: "space-evenly",
    },
    height: 430,
    borderBottom: "1px solid grey",
  },
  customerFeedbackCard: {
    [theme.breakpoints.down("md")]: {
      padding: 20,
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customerFeedbackDescription: {
    [theme.breakpoints.down("sm")]: {
      maxWidth: 256,
    },
    maxWidth: 524,
    width: "100%",
  },
  customerCompany: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 22,
      color: "#4C8F83",
    },
    fontSize: 32,
    fontWeight: theme.typography.semiBold,
  },
  customerCategoryLabel: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
    fontSize: 12,
    borderRadius: 0,
    background: "#FFFFFF",
  },
  customerFeedback: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    marginTop: 20,
    },
    color: "#333",
    marginTop: 37,
    marginBottom: 18,
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
  },
  customerPointOfContact: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
    fontSize: 20,
    fontWeight: theme.typography.semiBold,
  },
  customerPointOfContactPosition: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
    fontSize: 18,
    fontWeight: theme.typography.fontWeightMedium,
  },
  customerFeedbackVideoContainer: {
    [theme.breakpoints.down("md")]: {
      padding: 20,
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customerFeedbackVideoCardContainer: {
    [theme.breakpoints.down("sm")]: {
      width: 257,
      height: 171,
    },
    width: 386,
    height: 252,
    position: "relative",
  },
  customerFeedbackVideoCard: {
    height: "inherit",
  },
  customerFeedbackVideoPlayIconContainer: {
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
    },
    "&:hover": {
      background: "#F07663",
    },
    background: "radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%)",
    border: "2px solid white",
    cursor: "pointer",
    outline: "none",
    borderRadius: 64,
    width: 64,
    height: 64,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customerFeedbackVideoPlayIcon: {
    [theme.breakpoints.down("sm")]: {
      borderWidth: "10px 0px 10px 15px",
    },
    borderStyle: "solid",
    borderWidth: "16px 0px 16px 26px",
    borderColor: "transparent transparent transparent white",
    marginLeft: 7,
  },
  customerLogo: {
    [theme.breakpoints.down("sm")]: {
      left: "10px",
      top: "132px",
      width: "50px",
      height: "31px",
    },
    position: "absolute",
    top: 192,
  },
}));

const CustomerFeedback = () => {
  const classes = useStyles();
  const {
    customerFeedbackContainer,
    customerFeedbackGridContainer,
    customerFeedbackCard,
    customerFeedbackDescription,
    customerCompany,
    customerCategoryLabel,
    customerFeedback,
    customerPointOfContact,
    customerPointOfContactPosition,
    customerFeedbackVideoContainer,
    customerFeedbackVideoCardContainer,
    customerFeedbackVideoCard,
    customerFeedbackVideoPlayIconContainer,
    customerFeedbackVideoPlayIcon,
    customerLogo,
  } = classes;

  return (
    <Grid className={customerFeedbackContainer} container>
      {customerFeedbackList &&
        customerFeedbackList.map((feedbacks) => {
          return (
            <Grid
              container
              className={customerFeedbackGridContainer}
              key={feedbacks.id}
            >
              <Grid item lg={6} md={6} sm={6} className={customerFeedbackCard}>
                <div className={customerFeedbackDescription}>
                  <Typography className={customerCompany}>
                    {feedbacks.customerCompany}
                  </Typography>
                  <Chip
                    label={feedbacks.categoryLabel}
                    variant="outlined"
                    className={customerCategoryLabel}
                  />
                  <Typography className={customerFeedback}>
                    {feedbacks.customerFeedback}
                  </Typography>
                  <Typography className={customerPointOfContact}>
                    {feedbacks.customerPointOfContact}
                  </Typography>
                  <Typography className={customerPointOfContactPosition}>
                    {feedbacks.customerPointOfContactPosition}
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                sm={6}
                className={customerFeedbackVideoContainer}
              >
                <div className={customerFeedbackVideoCardContainer}>
                  <Card className={customerFeedbackVideoCard}>
                    <ReactPlayer
                      url={feedbacks.videoUrl}
                      width="inherit"
                      height="inherit"
                      light={feedbacks.videoImageSource}
                      playIcon={
                        <button
                          className={customerFeedbackVideoPlayIconContainer}
                        >
                          <div className={customerFeedbackVideoPlayIcon}></div>
                        </button>
                      }
                    />
                  </Card>

                  <img
                    className={customerLogo}
                    src={feedbacks.customerLogo}
                    alt="customerLogo"
                  />
                </div>
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default CustomerFeedback;
