import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import CustomerSupportLink from "./SubProductLink";
const useStyles = makeStyles((theme) => ({
  productContainer: {
    minHeight: 188,
  },
}));
const ProductsBox = (props) => {
  const { productContainer } = useStyles();

  return (
    <div className={productContainer}>
      <Grid container>
        {props.SubProductLinks.map((product) => (
          <Grid key={product.id} item xs={12} sm={6} lg={4}>
            <CustomerSupportLink
              disableHover={props.disableHover}
              {...product}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ProductsBox;
