import _ from "lodash";

export const RETAIL_CATEGORY = {
    "First": 1,
    "Second": 2,
    "Third": 3
};

export const RETAIL_SIZE = {
    "10-20":1,
    "20-30":2,
    "100+": 3
}
export function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
function makeid(length: any) {
    let result           = '';
    const characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}
export function createEsKey(BusinessName: string){
    return `${BusinessName.toLowerCase()}-${makeid(10)}`;
}
export function validatePasswordPattern(credentials: any){
    if(!_.isEqual(credentials.Password, credentials.ConfirmPassword)){
        return 1
    }
    const re  = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
    return re.test(credentials.Password) ? 3 : 2;
}
export const SCREEN_SIZE = window.screen.width <= 600 ? "small" : "medium";