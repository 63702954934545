import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  caption: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 24,
    fontFamily: "Montserrat",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      fontWeight: 800,
      color: "#333333",
      marginTop: 15,
    },
  },
}));
const Caption = (props) => {
  const { caption } = useStyles();
  const { align } = props;
  return (
    <Typography
      align={align || "center"}
      className={clsx(props.className, caption)}
    >
      {props.children}
    </Typography>
  );
};

export default Caption;
