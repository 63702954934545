export const bannerContent = {
  caption: "Launch targeted rewards and offers ",
  medium: "Offerio",
  content: `Flexible offer generator at product, Customer, Store, Period level`,
  button: "Get In Touch",
  source: require("../../assets/Products/offerio/undraw_online_wishes_dlmr.svg"),
};

export const platformContent = {
  title: "Offerio powers up sales & persona-linked offer management",
  caption: "You can now launch and manage offers automatically, intelligently.",
};

export const productCard = [
  {
    id: 1,
    title: "Acquire & reactivate customers",
    list: [
      "Instant rewards and offers",
      "AI-driven personalization",
      "Intent-driven deals",
    ],
  },
  {
    id: 2,
    title: "Omnichannel support",
    list: [
      "Easy control across channels",
      "Measure the impact",
      "Optimize offering",
    ],
  },
  {
    id: 3,
    title: "Value at scale",
    list: [
      "Deliver great experiences on the go",
      "Deliver at scale",
      "Support for varied types of offers",
    ],
  },
];

export const productTabList = [
  { id: 1, name: "Engage in real-time" },
  { id: 2, name: "Build loyalty" },
  { id: 3, name: "Retain with smart offers" },
];

export const productInfo = [
  {
    id: 1,
    source: require("../../assets/Products/offerio/profiling_monochromatic.svg"),
    content: {
      title: "Engage in real-time",
      details:
        "Send customers near real-time,event-driven offers and deals which is hyper personalized based on behaviours and intent",
    },
  },
  {
    id: 2,
    source: require("../../assets/Products/offerio/marketing_monochromatic.svg"),
    content: {
      title: "Build loyalty",
      details:
        "Link rewards to CRM and loyalty systems, enabling customers to patronize the brand",
    },
  },
  {
    id: 3,
    source: require("../../assets/Products/offerio/credit_card_monochromatic.svg"),
    content: {
      title: "Retain with smart offers",
      details:
        "Drive customers to realize and share offers, rewarding them for downstream activities (purchasing)",
    },
  },
];

export const demoContents = {
  caption: "Let's devise personalized offers for the customers",
  button: "Book a Demo",
};

export default {
  bannerContent,
  platformContent,
  productCard,
  productTabList,
  productInfo,
};
