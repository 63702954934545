import { IconButton } from '@material-ui/core';
import { HomeRounded } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';

import casaLogo from '../../../assets/Home/newHome/CasaLogo.png';
import styles from './NavBar.module.scss';

const NavBar = () => {
  return (
    <header id={styles.header}>
      <div className={styles.headerContent}>
        <div style={{ display: 'flex' }}>
          <img className={styles.logo} src={casaLogo} alt='casaLogo' />
        </div>
        <IconButton
          disableRipple
          style={{ color: '#fff' }}
          component={Link}
          to='/home'
        >
          <HomeRounded className={styles.homeIcon} color='inherit' />
        </IconButton>
      </div>
    </header>
  );
};

export default NavBar;
