import React from "react";
import Helmet from "react-helmet";

const HeadTagDetails = () => {
  return (
    <>
      <Helmet>
        <title>CDP | CRM | AI - Make Selling Easier with Casa Retail</title>
        <meta
          name="description"
          lang="en"
          content="A thinking retailer’s omnichannel, personalized customer engagement CRM designed by consumer experts, data scientists and marketing strategists"
        ></meta>
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="CDP | CRM | AI - Make Selling Easier with Casa Retail"
        />
        <meta
          property="og:description"
          content="A thinking retailer’s omnichannel, personalized customer engagement hub designed by consumer experts, data scientists and marketing strategists"
        />
        <meta property="og:url" content="https://casaretail.ai" />
        <meta name="twitter:site" content="@casaretail_ai" />
        <meta
          name="twitter: title"
          content="CRM Software - Make Selling Easier with Casa Retail"
        />
        <meta
          name="twitter:description"
          content="A thinking retailer’s omnichannel, personalized customer engagement CRM designed by consumer experts, data scientists and marketing strategists"
        />
      </Helmet>
    </>
  );
};

export default HeadTagDetails;
