import React from 'react';

import { modules } from '../../../utils/newHomePage';
import styles from './Banner.module.scss';
import UpgradedModules from './UpgradedModules';

const whatsNew = [
  {
    title: 'Advanced AI-driven Marketing',
    description: 'Go from insights to action in a flash',
  },
  {
    title: 'Brand New UI',
    description: 'Making Marketing Effortless',
  },
  {
    title: 'State of the Art Modules',
    description: 'Step into the future of Personalization',
  },
];

const Banner = () => {
  return (
    <section id={styles.bannerContainer}>
      <div className={styles.titleContainer}>
        <h1 className={styles.title}>Welcome to CASA 2.0</h1>
        <p className={styles.description}>Elevating Customer Engagement</p>
        <a className={styles.contactUs} href='mailto:growmybiz@casaretail.ai'>
          Contact Us
        </a>
      </div>
      <div className={styles.whatsNew}>
        <div className={styles.whatsNewHeader}>
          <h3>What's New?</h3>
        </div>
        <div className={styles.whatsNewBody}>
          {whatsNew.map(({ title, description }, index) => (
            <>
              <div className={styles.whatsNewItem} key={index}>
                <h5>{title}</h5>
                <p>{description}</p>
              </div>
              {whatsNew.length > index + 1 && (
                <div className={styles.divider} />
              )}
            </>
          ))}
        </div>
      </div>
      <div className={styles.modulesContainer}>
        <h3 className={styles.moduleMainTitle}>Module wise Upgrades</h3>
        <div className={styles.modules}>
          {modules.map(({ logo, title, subtitle, points, background }) => (
            <UpgradedModules
              logo={logo}
              title={title}
              background={background}
              subtitle={subtitle}
              points={points}
            />
          ))}
        </div>
      </div>
      <div className={styles.contact}>
        <p>Reach Us On</p>
        <a  href='mailto:growmybiz@casaretail.ai'>
          growmybiz@casaretail.ai
        </a>
      </div>
    </section>
  );
};
export default Banner;
