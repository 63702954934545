import React from "react";
import CraftsMan from "./CraftsMan";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";

const Index = (props) => {
  return (
    <>
      <Navbar />
      <CraftsMan />
      <Footer />
    </>
  );
};

export default Index;
