import React from "react";
import { Container, Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    height: 350,
    display: "flex",
    alignItems: "center",
  },
  contentWrapper: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
    },
  },
  content: {
    width: "62%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  title: {
    fontSize: 32,
    fontWeight: "bolder",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 18,
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
  },
  productInfoContent: {
    lineHeight: 1.6,
    fontSize: "20px",
    marginTop: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      textAlign: "center",
    },
  },
  productBanner: {
    width: "100%",
    height: "280px",
    objectFit: "initial",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "240px",
      objectFit: "contain",
    },
  },

  tabContent: {
    height: "350px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "auto",
      justifyContent: "center",
    },
  },
}));

export default function TabContents(props) {
  const { productInfo } = props;
  const {
    contentWrapper,
    productBanner,
    content,
    title,

    tabContent,
    productInfoContent,
  } = useStyles();

  return (
    <Container className={tabContent}>
      {/* <div className={wrapper}> */}
      <Grid container>
        <Grid item sm={4}>
          <img
            src={productInfo.source}
            alt="ProductInfo"
            className={productBanner}
          />
        </Grid>
        <Grid item sm={8}>
          <div className={contentWrapper}>
            <div className={content}>
              <div className={title}>{productInfo.content.title}</div>
              <p className={productInfoContent}>
                {productInfo.content.details}
              </p>
              <p>{productInfo.content.conclusion}</p>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* </div> */}
    </Container>
  );
}
