import { Typography, makeStyles } from "@material-ui/core";
import React from "react";
// import HeroButton from "../../../components/heroBtn";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f4f5f7",
    fontWeight: 900,
    fontFamily: "'Nunito'",
  },
  rootAlign: {
    width: "80%",
    padding: "28px 0",
  },
  logoImage: {
    marginBottom: 24,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
      height: 50,
    },
  },
  position: {
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.35em",
    },
  },
  location: {
    fontSize: 19,
    marginTop: 4,
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
  },
  listGroup: {
    margin: "20px 0",
    [theme.breakpoints.down("sm")]: {
      margin: "10px 0",
    },
  },
  aboutList: {
    display: "flex",
    alignItems: "center",
  },
  listContent: {
    margin: "12px 8px",
    fontFamily: "'Nunito'",
    fontSize: 18,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
  bottom: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  aboutCompany: {
    margin: "32px 0",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      margin: "24px 0",
    },
  },
  logoImagecontainer: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  linkStyleContent: {
    color: "white",
    background: "#F07663",
    textDecoration: "none !important",
    padding: 10,

    borderRadius: 10,
  },
}));

const PageCaption = (props) => {
  const role = props.role;
  const {
    root,
    rootAlign,
    logoImage,
    aboutList,
    listContent,
    listGroup,
    position,
    location,
    linkStyleContent,
    bottom,
    aboutCompany,
    logoImagecontainer,
  } = useStyles();

  return (
    <div className={root}>
      <div className={rootAlign}>
        <div className={logoImagecontainer}>
          <img
            src={role.logo}
            className={logoImage}
            alt="Casa-RetailAI-Dark"
          ></img>
          <h1 className={position}>{role.position}</h1>
          <Typography className={location}>{role.location}</Typography>
        </div>
        <div className={listGroup}>
          {role.list.map((list) => (
            <div className={aboutList}>
              <img
                src={require("../../../assets/careers/check.svg")}
                alt="Checked"
              ></img>
              <Typography className={listContent}>{list.content}</Typography>
            </div>
          ))}
        </div>
        <div className={bottom}>
          <a href="mailto:recruit@casaretail.ai" className={linkStyleContent}>
            Apply Now
          </a>
          {/* <HeroButton
            onClick={() =>
              window.ml_account("webforms", "5211053", "u6u0x4", "show")
            }
          >
            Apply Now
          </HeroButton> */}
          <Typography className={aboutCompany}>{role.aboutCompany}</Typography>
        </div>
      </div>
    </div>
  );
};

export default PageCaption;
