export class TenantUsersModel{
    public userId: string;
    public password: string;
    public phNo: string;
    public role: string;
    constructor(props: any) {
        this.userId = props.EmailId;
        this.password = props.password;
        this.role = props.role;
        this.phNo = props.MobileNumber;
    }
}