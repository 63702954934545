import React from "react";
import Helmet from "react-helmet";

const HeadTagDetails = () => {
  return (
    <>
      <Helmet>
        <title>Integrated Digital Channels | Pimkart</title>
        <meta
          name="description"
          lang="en"
          content="Delight new age shoppers with flair using PIMkart & engage shoppers consistently across channels"
        ></meta>
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Integrated Digital Channels | PIMkart"
        />
        <meta
          property="og:description"
          content="Delight new age shoppers with flair using PIMkart & engage shoppers consistently across channels"
        />
        <meta
          property="og:url"
          content="https://casaretail.ai/products/pimkart"
        />
        <meta name="twitter:site" content="@casaretail_ai" />
        <meta
          name="twitter: title"
          content="Integrated Digital Channels | PIMkart"
        />
        <meta
          name="twitter:description"
          content="Delight new age shoppers with flair using PIMkart & engage shoppers consistently across channels"
        />
      </Helmet>
    </>
  );
};

export default HeadTagDetails;
