export const bannerContent = {
  caption: "Succeed with retail customer intelligence",
  medium: "PWA",
  content: `Identify actionable insights with AI powered customer data platform and analytics `,
  button: "Get In Touch",
  source: require("../../assets/Products/pwa/undraw_gift_card_6ekc.svg"),
};

export const platformContent = {
  title: "PWA powers your customer engagement",
  caption:
    "Strategic customer engagement while providing tactical opportunities for immediate benefit",
};

export const productCard = [
  {
    id: 1,
    title: "Path to value",
    list: [
      "Create experiences built on behaviors and motivations",
      "Create customer journeys that resonate",
    ],
  },
  {
    id: 2,
    title: "AI-driven strategy",
    list: [
      "Assess the full lifecycle customer experience",
      "Action driven opportunities",
    ],
  },
  {
    id: 3,
    title: "Value at scale",
    list: [
      "Gain key insights ",
      "Understand hidden opportunities from your data",
    ],
  },
];

export const productTabList = [
  { id: 1, name: "Seize the moment" },
  { id: 2, name: "Break the barriers" },
  { id: 3, name: "Set the pace" },
];

export const productInfo = [
  {
    id: 1,
    source: require("../../assets/Products/pwa/social_media_monochromatic.svg"),
    content: {
      title: "Seize the moment",
      details:
        "You can now reach customers smartly in sync with their tastes and needs and at speed in their channel of choice",
    },
  },
  {
    id: 2,
    source: require("../../assets/Products/pwa/order_confirmation_monochromatic.svg"),
    content: {
      title: "Break the barriers",
      details:
        "PWA actionable insights enable you to change with dynamic customer preferences and lead them through their journey",
    },
  },
  {
    id: 3,
    source: require("../../assets/Products/pwa/online_store__monochromatic (1).svg"),
    content: {
      title: "Set the pace",
      details:
        "With AI-driven flexibility, you can now be on top of customer dynamics, leading them down the marketing funnel in a more predictable manner",
    },
  },
];

export const demoContents = {
  caption: "Lets get the intelligence right about customers",
  button: "Book a Demo",
};

export default {
  bannerContent,
  platformContent,
  productCard,
  productTabList,
  productInfo,
};
